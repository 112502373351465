import { createSlice } from '@reduxjs/toolkit';

const storedPaymentRes = localStorage.getItem('PAYMENT-RESPONSE');
const paymentResponse = storedPaymentRes ? JSON.parse(storedPaymentRes) : {};

const initialState = {
    paymentResponse
}
export const paymentSlice = createSlice({
    name: 'payment',
    initialState: initialState,
    reducers: {
        setPaymentResponse(state, action) {
            state.paymentResponse = action.payload;
            localStorage.setItem("PAYMENT-RESPONSE", JSON.stringify(action.payload));
        },
        removePaymentResponse(state) {
            state.paymentResponse = '';
            localStorage.removeItem('PAYMENT-RESPONSE');
        }
    },
})

export const paymentActions = paymentSlice.actions;
export default paymentSlice.reducer;
