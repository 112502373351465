import React from 'react';
import * as PR from "../../prime-modules/index";
import MakePayment from '../payment/MakePayment';

const PaymentModal = (props) => {
    return (
        <>
    <PR.Dialog
    visible={props?.checkoutFormState}
    className="forms-dialog payment-dialog"
    blockScroll={true}
    draggable={false}
    style={{ width: "75%" }}
    onHide={() => props?.setCheckoutFormState(false)}
>
        <MakePayment setCheckoutFormState={props?.setCheckoutFormState} affiliatePath={props?.affiliatePath} setAffiliatePage={props.setAffiliatePage}/>
    </PR.Dialog>
        </>
    );
};

export default PaymentModal;