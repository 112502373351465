import React from 'react'
import { Link } from "react-router-dom";
import * as PR from "../../prime-modules";
import { useToast } from '../../context/ToastContext';


const EsimActivationDialog = ({smdpAddress,matchingId, activeeSIM, setActiveeSIM}) => {
    const {labels} = useToast();
  return (
    <PR.Dialog header="" visible={activeeSIM} headerStyle={{ padding: 4}} draggable={false} blockScroll={true} className="forms-dialog-popup" resizable={false} style={{ width: '85vw'}} onHide={() => { if (!activeeSIM) return; setActiveeSIM(false); }}>
    <p className="esim-popup-title">
      {labels?.LBL0153}
    </p>
    <p className="esim-popup-subtitle">
    {labels?.LBL0154}
    </p>
    <Link to={`https://esimsetup.apple.com/esim_qrcode_provisioning?carddata=LPA:1$${smdpAddress}$${matchingId}`} onClick={()=>setActiveeSIM(false)} target='_blank' rel='noreferrer'>
      <div className="esim-popup-button">
        <PR.Button
          label={labels?.LBL0152}
          className="choose-popup-button p-button-rounded"
        />
      </div>
    </Link>
  </PR.Dialog>
  )
}

export default EsimActivationDialog
