import { createSlice } from '@reduxjs/toolkit';
const getsessionData = localStorage.getItem('sessionData');
const getidPath = localStorage.getItem('USERID-PATH');
const getaffiliatePath = localStorage.getItem('AFFILIATE-PATH');

const sessionData = (getsessionData === 'undefined' || getsessionData === "") ? JSON.stringify(getsessionData) : (getsessionData !== null) ? JSON.parse(getsessionData): {};
const isLoggedIn = (getsessionData === 'undefined' || getsessionData === "") ? false : getsessionData !== null ? true : false;
const idPath = (getidPath === 'undefined' || getidPath === "") ? JSON.stringify(getidPath) : (getidPath !== null) ? JSON.parse(getidPath): {};
const affiliatePath = (getaffiliatePath === 'undefined' || getaffiliatePath === "") ? JSON.stringify(getaffiliatePath) : (getaffiliatePath !== null) ? JSON.parse(getaffiliatePath): {};

const initialAuthState = {
    isLoggedIn: isLoggedIn,
    sessionData: sessionData,
    idPath: idPath,
    affiliatePath: affiliatePath,
    affiliateExsitingUser:false,

};

const authSlice = createSlice({
    name: 'authentication',
    initialState: initialAuthState,
    reducers: {
        onLogin(state, action) {
            const storedLoginInfo = {
                sessionId: action.payload.sessionId ?? state?.sessionData?.sessionId,
                userId: action.payload.userId ?? state?.sessionData?.userId,
                firstName: action.payload.firstName ?? state?.sessionData?.firstName,
                lastName: action.payload.lastName ?? state?.sessionData?.lastName,
                email: action.payload.email ?? state?.sessionData?.email,
                phone: action.payload.phone ?? state?.sessionData?.phone,
                signupType: action.payload.signupType ?? state?.sessionData?.signupType

            }
            state.isLoggedIn = true;
            state.sessionData = storedLoginInfo;
            localStorage.setItem('sessionData', JSON.stringify(storedLoginInfo));
        },
        onLogout(state) {
            state.isLoggedIn = false;
            state.sessionData = {};
            localStorage.removeItem('sessionData');
            localStorage.removeItem('ALLCOUNTRIES');
            localStorage.removeItem('PHONECOUNTRY');
        },
        storeIdPath(state, action) {
            state.idPath = action.payload
            localStorage.setItem('USERID-PATH', JSON.stringify(action.payload));
        },
        storeAffiliatePath(state, action) {
            state.affiliatePath = action.payload
            localStorage.setItem('AFFILIATE-PATH', JSON.stringify(action.payload));
        },
        setAffiliateExsitingUserStatus(state, action) {
            state.affiliateExsitingUser = action.payload
        },

        deleteStoreIdPath(state){
            state.idPath = {};
            localStorage.removeItem('USERID-PATH');
            localStorage.removeItem('AFFILIATE-PATH');
        }
    },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;