import { getFreeeEsimDetails } from "../../services/api";
import { authActions } from "../../store/auth";
import { cartFilterActions } from "../../store/cart";
import { ynertiaPaymentResponseActions } from "../../store/ynertiaResponse";
import { setNumberPrecision } from "../../utils/reuse";

export const checkoutOrder = (bundleDetails, dispatch) => {
    const catalogData = bundleDetails;
    const catalogName = catalogData.name;
    const catalogPrice = setNumberPrecision(catalogData.price);

    const setCartData = {
        name: catalogName,
        description: catalogData.description,
        quantity: 1,
        price: +catalogPrice,
        speed: catalogData.speed,
        duration: catalogData.duration,
        dataAmount: catalogData.dataAmount,
        dataAmountForDisplay: catalogData.dataAmountForDisplay,
    };
    dispatch(cartFilterActions.setCatalogCart(setCartData));
    dispatch(cartFilterActions.setCatalogCartAmount(+catalogPrice));
};

export const getFinalEsimDetails = (props, dispatch, showToast, userData, affiliatePath, headers) => {
    let data = {
        contactInfo: {
            firstName: userData.firstName,
            email: userData.email,
        },
        orderSummary: {
            dataPlans: [
                {
                    name: affiliatePath?.bundleId,
                    quantity: 1
                }
            ],
            totalOrderAmount: 0
        },
    }
    props?.setLoading(true);
    const getBundle = (response) => {
        if (response.result === "SUCCESS") {
            const finalResponse = {
                iccid: response?.data?.paymentResponse.iccid,
                profileStatus: response?.data?.paymentResponse.profileStatus,
                finalRedirectURL: response?.data?.finalRedirectURL
            };
            dispatch(ynertiaPaymentResponseActions.setYnertiaPaymentResponse(finalResponse));
            props?.setAffiliatePage("esimDetails");
            props?.setLoading(false);
        } else {
            props?.setLoading(false);
            const error = response.error;
            const errorMsg = error.errorMsg ? error.errorMsg : error.summary;
            showToast("error",errorMsg)
        }
    }
    getFreeeEsimDetails(data, headers, dispatch, getBundle)
};

export const checkFreeSims = (
    affiliatePath,
    localPhoneNumber,
    freeSims,
    dispatch,
    props,
    showToast,
    userData,
    headers
) => {
    if (localPhoneNumber === '') {
        props?.setAffiliatePage("verifyPhone");
    } else {
        const { isFree } = affiliatePath;
        if (isFree) {
            if (freeSims === 0) {
                dispatch(authActions.setAffiliateExsitingUserStatus(true));
                props?.setAffiliatePage("existingUser");
            } else {
                const newHeaders = { ...headers, sessionid: userData?.sessionId };
                getFinalEsimDetails(props, dispatch, showToast, userData, affiliatePath, newHeaders);
            }
        } else {
            props?.setCheckoutFormState(true);
        }
    }
};