import React, { useState } from 'react'
import { Link } from "react-router-dom";
import * as PR from "../../prime-modules/index";
import { visa, masterCard, appleIcon, gpayIcon } from "../../assets/images";
import { globalConfig } from '../../constants';
import { scrollToBundlesActions } from '../../store/scrollToBundles';
import { useDispatch } from 'react-redux';
import { scrollTop } from "../../utils/reuse";
import { useToast } from '../../context/ToastContext';
import getAssets from "../../assets/data/brandingAssets.json"

const Footer = () => {
  const { labels, general, isBase64Image } = useToast();
  const dispatch = useDispatch();
  const [year] = useState(new Date().getFullYear());

  const pricingHandler = () => {
    dispatch(scrollToBundlesActions.setScrollToBundles(true));
  }

  return (
    <>
      <footer>
        <div className='footer-info grid grid-nogutter'>
          <div className='col-12 lg:col-3 md:col-6 footer-logo'>
            <Link to="/">
              <PR.Image className="logo" src={isBase64Image(general.GEN0002) ? `data:image/png;base64,${general.GEN0002}` : getAssets.logo.logo_dark} alt="logo" />
            </Link>
            <br />
            <span className='social-span'>{labels.LBL0063}</span>
            <ul>
              <li><Link to={"https://www.facebook.com/data2go.mobi"} target='_blank' rel='noreferrer'className='social-image facebook'></Link></li>
              <li><Link to={"https://twitter.com/data2go_mobi"} target='_blank' rel='noreferrer'className='social-image twitter'></Link></li>
              <li><Link to={"https://www.youtube.com/@data2go_mobi"} target='_blank' rel='noreferrer'className='social-image youtube'></Link></li>
            </ul>
          </div>
          <div className='col-12 lg:col-3 md:col-6'>
            <h2>{labels.LBL0299}</h2>
            <div className='footer-links'>
              <Link to={"/"} onClick={scrollTop}>{labels.LBL0082}</Link>
              <Link to={"/"} onClick={pricingHandler}>{labels.LBL0300}</Link>
              <Link to="/partners">{labels.LBL0303}</Link>
              <Link to="/affiliate">{labels.LBL0304}</Link>
              <Link to="/about-us">{labels.LBL0084}</Link>
              <Link to={"/contact"} >{labels.LBL0125}</Link>
            </div>
          </div>
          <div className='col-12 lg:col-3 md:col-6'>
            <h2>{labels.LBL0301}</h2>
            <div className='footer-links'>
              <Link to="/faq">{labels.LBL0083}</Link>
              <Link to="/terms-and-conditions">{labels.LBL0178}</Link>
              <Link to="/privacy-policy">{labels.LBL0075}</Link>
              <Link to="/cookie-policy">{labels.LBL0128}</Link>
            </div>
          </div>
          <div className='col-12 lg:col-3 md:col-6'>
            <h2>{labels.LBL0170}</h2>
            <div className='footer-links'>
              <Link to={`tel:${globalConfig.phoneNumber}`}>{globalConfig.phoneNumber}</Link>
              <Link to={`mailto:${globalConfig.supportEmail}`}>{globalConfig.supportEmail}</Link>
            </div>
          </div>
        </div>
        <div className='grid grid-nogutter align-items-center justify-content-between copyrights-div'>
          <p>&copy;{year} <Link to="/">{globalConfig.domainName}.</Link> {labels.LBL0065} | {labels.LBL0064} <Link target='_blank' className='underline' to="https://www.bluehouse-technology.co.uk/">Bluehouse Technology.</Link></p>
          <div className='right'>
            <span>{globalConfig.domainName + " " + labels.LBL0086}</span>
            <ul className='flex align-items-center'>
              <li><PR.Image src={visa} alt="Visa" /></li>
              <li><PR.Image src={masterCard} alt="Master Card" /></li>
              <li><PR.Image src={appleIcon} alt="Apple Pay" /></li>
              <li><PR.Image src={gpayIcon} alt="Google Pay" /></li>
            </ul>
          </div>
        </div>
        <PR.ScrollTop className='scroll-to-top' icon="pi pi-arrow-up" />
      </footer>
    </>
  )
}
export default Footer