import React from "react";
import "../compatible-devices/CompatibleDevices.scss";
import * as PR from "../../prime-modules/index";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { appleDevices, googleDevices, huaweiDevices, otherDevices, samsungDevices } from "../../assets/images";
import SubHeader from "../../components/SubHeader";
import SupportMailsSection from "../../components/SupportMailsSection";
import { useToast } from "../../context/ToastContext";

const CompatibleDevices = () => {
  const {labels} = useToast()

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>data2go | Compatible Devices</title>
      </Helmet>

      {/* Header Section */}
      <SubHeader header={labels?.LBL0113} subText={labels?.LBL0114} />

      <section className="about-blocks-section compatible-devices-section">
        <div className="custom-container">
          <p className="text-center mb-6"><span className="font-medium">{labels?.LBL0115}:</span> {labels?.LBL0116} <span>{labels?.LBL0117}</span> {labels?.LBL0119}</p>
          <div className="faq-tabs device-tabs">
            <PR.TabView>
              <PR.TabPanel header={labels?.LBL0118}>
                <div className="device-panel">
                  <div className="grid grid-nogutter">
                    <div className="col-12 lg:col-4 md:col-4">
                        <ul>
                          <li>iPhone XR</li>
                          <li>iPhone XS</li>
                          <li>iPhone XS Max</li>
                          <li>iPhone 11</li>
                          <li>iPhone 11 Pro</li>
                          <li>iPhone SE 2 (2020)</li>
                          <li>iPhone SE 3 (2022)</li>
                          <li>iPhone 12</li>
                          <li>iPhone 12 Mini</li>
                          <li>iPhone 12 Pro</li>
                          <li>iPhone 12 Pro Max</li>
                        </ul>
                    </div>
                    <div className="col-12 lg:col-4 md:col-4">
                        <ul>
                          <li>iPhone 13</li>
                          <li>iPhone 13 Mini</li>
                          <li>iPhone 13 Pro</li>
                          <li>iPhone 13 Pro Max</li>
                          <li>iPhone 14</li>
                          <li>iPhone 14 Plus</li>
                          <li>iPhone 14 Pro</li>
                          <li>iPhone 14 Pro Max</li>
                          <li>iPhone 15</li>
                          <li>iPhone 15 Plus</li>
                          <li>iPhone 15 Pro</li>
                          <li>iPhone 15 Pro Max</li>
                        </ul>
                    </div>
                    <div className="col-12 lg:col-4 md:col-4">
                        <ul>
                          <li>iPad Pro 11″ (model A2068, from 2020)</li>
                          <li>iPad Pro 12.9″ (model A2069, from 2020)</li>
                          <li>iPad Air (model A2123, from 2019)</li>
                          <li>iPad (model A2198, from 2019)</li>
                          <li>iPad Mini (model A2124, from 2019)</li>
                        </ul>
                    </div>
                  </div>
                  <div className="line"></div>
                  <PR.Image className="devices-img" src={appleDevices} alt="Apple Devices"/>
                </div>
              </PR.TabPanel>
              <PR.TabPanel header={labels?.LBL0120}>
                <div className="device-panel">
                  <div className="grid grid-nogutter">
                    <div className="col-12 lg:col-4 md:col-4">
                        <ul>
                          <li>Samsung Galaxy S20</li>
                          <li>Samsung Galaxy S20+</li>
                          <li>Samsung Galaxy S20 Ultra</li>
                          <li>Samsung Galaxy S21</li>
                          <li>Samsung Galaxy S21+ 5G</li>
                          <li>Samsung Galaxy S21+ Ultra 5G</li>
                        </ul>
                    </div>
                    <div className="col-12 lg:col-4 md:col-4">
                        <ul>
                          <li>Samsung Galaxy S22</li>
                          <li>Samsung Galaxy S22+</li>
                          <li>Samsung Galaxy S22 Ultra</li>
                          <li>Samsung Galaxy Note 20</li>
                          <li>Samsung Galaxy Note 20 Ultra 5G</li>
                          <li>Samsung Galaxy Fold</li>
                        </ul>
                    </div>
                    <div className="col-12 lg:col-4 md:col-4">
                        <ul>
                          <li>Samsung Galaxy Z Fold2 5G</li>
                          <li>Samsung Galaxy Z Fold3 5G</li>
                          <li>Samsung Galaxy Z Fold4</li>
                          <li>Samsung Galaxy Z Flip</li>
                          <li>Samsung Galaxy Z Flip3 5G</li>
                          <li>Samsung Galaxy Z Flip4</li>
                        </ul>
                    </div>
                  </div>
                  <div className="line"></div>
                  <PR.Image className="devices-img" src={samsungDevices} alt="Apple Devices"/>
                </div>
              </PR.TabPanel>
              <PR.TabPanel header={labels?.LBL0121}>
                  <div className="device-panel">
                    <div className="grid grid-nogutter">
                      <div className="col-12 lg:col-4 md:col-4">
                          <ul>
                            <li>Google Pixel 2</li>
                            <li>Google Pixel 2 XL</li>
                            <li>Google Pixel 3</li>
                            <li>Google Pixel 3 XL</li>
                            <li>Google Pixel 3a</li>
                            <li>Google Pixel 3a XL</li>
                          </ul>
                      </div>
                      <div className="col-12 lg:col-4 md:col-4">
                          <ul>
                            <li>Google Pixel 4</li>
                            <li>Google Pixel 4a</li>
                            <li>Google Pixel 4 XL</li>
                            <li>Google Pixel 5</li>
                            <li>Google Pixel 5a</li>
                          </ul>
                      </div>
                      <div className="col-12 lg:col-4 md:col-4">
                          <ul>
                            <li>Google Pixel 6</li>
                            <li>Google pixel 6a</li>
                            <li>Google Pixel 6 Pro</li>
                            <li>Google Pixel 7</li>
                            <li>Google Pixel 7 Pro</li>
                          </ul>
                      </div>
                    </div>
                    <div className="line"></div>
                    <PR.Image className="devices-img" src={googleDevices} alt="Apple Devices"/>
                  </div>
              </PR.TabPanel>
              <PR.TabPanel header={labels?.LBL0122}>
                <div className="device-panel">
                  <div className="grid grid-nogutter">
                    <div className="col-12 lg:col-4 md:col-4">
                        <h2>{labels?.LBL0261}</h2>
                        <ul>
                          <li>Huawei P40</li>
                          <li>Huawei P40 Pro</li>
                          <li>Huawei Mate 40 Pro</li>
                        </ul>
                    </div>
                    <div className="col-12 lg:col-4 md:col-4">
                        <h2>{labels?.LBL0262}</h2>
                        <ul>
                          <li>Oppo Find X3 Pro</li>
                          <li>Oppo Reno 5A</li>
                          <li>Oppo Find X5</li>
                          <li>Oppo Find X5 Pro</li>
                        </ul>
                    </div>
                  </div>
                  <div className="line"></div>
                  <PR.Image className="devices-img" src={huaweiDevices} alt="Apple Devices"/>
                </div>
              </PR.TabPanel>
              <PR.TabPanel header={labels?.LBL0123} className="last-tab">
                <div className="device-panel">
                  <h2>{labels?.LBL0124}</h2>
                  <div className="grid grid-nogutter mb-3">
                  <div className="col-12 lg:col-4 md:col-4">
                      <ul>
                        <li>Microsoft Surface Pro X</li>
                        <li>Microsoft Surface Go 2</li>
                        <li>Microsoft Surface Pro LTE Advanced</li>
                        <li>Acer Swift 3</li>
                        <li>Acer Swift 7</li>
                        <li>Asus Mini Transformer</li>
                      </ul>
                  </div>
                  <div className="col-12 lg:col-4 md:col-4">
                      <ul>
                        <li>Asus NovaGo</li>
                        <li>Asus VivoBook Flip 14</li>
                        <li>HP Elitebook G5</li>
                        <li>HP Probook G5</li>
                        <li>HP Zbook G5</li>
                      </ul>
                    </div>
                    <div className="col-12 lg:col-4 md:col-4">
                      <ul>
                        <li>HP Specter Folio 13</li>
                        <li>Lenovo Yoga C630</li>
                        <li>Lenovo Miix 630</li>
                        <li>Lenovo Yoga 520</li>
                        <li>Samsung Galaxy Book 2</li>
                      </ul>
                    </div>
                  </div>
                  <h2>{labels?.LBL0123}</h2>
                  <div className="grid grid-nogutter">
                  <div className="col-12 lg:col-4 md:col-4">
                      <ul>
                        <li>Motorola Razr 2019</li>
                        <li>Motorola Razr 5G</li>
                        <li>Gemini PDA</li>
                        <li>Rakuten Mini</li>
                        <li>Rakuten Big-S</li>
                        <li>Rakuten Big</li>
                      </ul>
                  </div>
                  <div className="col-12 lg:col-4 md:col-4">
                      <ul>
                        <li>Rakuten Hand</li>
                        <li>Rakuten Hand 5G</li>
                        <li>Sony Xperia 10 III Lite</li>
                        <li>Sony Xperia 10 IV</li>
                        <li>Xperia 1 IV</li>
                        <li>Surface Pro X</li>
                      </ul>
                    </div>
                    <div className="col-12 lg:col-4 md:col-4">
                      <ul>
                        <li>Honor Magic 4 Pro</li>
                        <li>Fairphone 4</li>
                        <li>Sharp Aquos Sense6s</li>
                        <li>Sharp Aquos Wish</li>
                        <li>Xiaomi 12T Pro</li>
                        <li>DOOGEE V30</li>
                      </ul>
                    </div>
                  </div>
                  <div className="line"></div>
                  <PR.Image className="devices-img" src={otherDevices} alt="Apple Devices"/>
                </div>
              </PR.TabPanel>
            </PR.TabView>
          </div>
        </div>
      </section>

      <section className="inner-header-section aboutus-header-section support-section">
        <SupportMailsSection/>
      </section>
    </HelmetProvider>
  );
};

export default CompatibleDevices;