import { useSelector } from "react-redux";
import { useToast } from "../../../context/ToastContext";

const EsimStatusHeader = () => {
    const {labels} = useToast();
    const iccid = useSelector(state => state.esim.esimIccid);
    const tag = useSelector(state => state.esim.tag);
    const isAuth = useSelector(state => state.auth.isLoggedIn);

    return (
      (isAuth && iccid) && <section className="info-section">
        <div className="grid grid-nogutter">
          <div className="col-12">
              <div className="info-box">
                <div>
                  <ul>
                    <li><p className="infoText"><span className="info-icon"><i className="pi pi-info"></i></span> {(isAuth && iccid) ? labels.LBL0165: labels.LBL0166}</p></li>
                    <li> | </li>
                    <li>{(isAuth && iccid) && <p className="iccidText"><span>ICCID: </span>{iccid} {tag ? '('+ tag +')' : ''}</p>}</li>
                  </ul>
                </div>
              </div>
          </div>
        </div>
      </section>
    )
}
export default EsimStatusHeader;