import React, { useState, useEffect, useRef, useMemo } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from "react-i18next";
import * as PR from "../../../prime-modules/index";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from 'react-redux';

import "./FreeEsimSignUp.scss";
import { globalConfig } from '../../../constants';
import CustomPhoneNumber from '../../CustomPhoneNumber';
import GoogleLoginComponent from "../../login/GoogleLogin";
import AppleLoginComponent from "../../login/AppleLogin";
import { signup, verifyotp, reSendOtp, updateUserProfile, changeUserPassword } from '../../../services/api';
import { authActions } from "../../../store/auth";
import { parsePhoneNumber } from 'react-phone-number-input'
import { checkFreeSims } from "../../../shared/methods/affiliateCheckout";
import PaymentModal from "../PaymentModal";
import { ynertiaPaymentResponseActions } from "../../../store/ynertiaResponse";

const GetFreeSim = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const toast = useRef();
    const userData = useSelector(state => state.auth.sessionData);
    const affiliatePath = useSelector(state => state.auth.affiliatePath);
    const headers = useMemo(() => {
        return { afid: affiliatePath?.afid };
    }, [affiliatePath?.afid]);

    const [dateRange, setDateRange] = useState(null);
    const [mobileVerifyStatus, setMobileVerifyStatus] = useState(undefined)
    const [otpVerifyStatus, setOtpVerifyStatus] = useState(false)
    const [emailStatus, setEmailStatus] = useState(undefined)
    const emailStatusRef = useRef(undefined)
    const passwordStatus = useRef(false)
    const dateState = useRef(false)
    const nameStatus = useRef(false)
    const otpVerifyStatusRef = useRef(false)
    const mobileVerifyStatusRef = useRef(undefined)
    const [otpShow, setOtpShow] = useState(false)
    const [phoneValue, setPhoneValue] = useState('');
    const [phoneError, setphoneError] = useState("");
    const [nameError, setNameError] = useState("")
    const [checkoutFormState, setCheckoutFormState] = useState(false);
    const [disableButtonStatus, setdisableButtonStatus] = useState(true);
    const [dateError, setDateError] = useState("")
    const [loading, setLoading] = useState(false)

    const [seconds, setSeconds] = useState(30);
    const [isCounting, setIsCounting] = useState(false);

    useEffect(() => {
        if (isCounting && seconds > 0) {
            const timerId = setTimeout(() => setSeconds(seconds - 1), 1000);
            return () => clearTimeout(timerId);
        } else if (seconds === 0) {
            setIsCounting(false);
        }
    }, [seconds, isCounting]);

    const formInitialValues = {
        fullName: "",
        email: "",
        phone: "",
        password: "",
        otp: "",
    };
    const validationSchema = () => {
        return Yup.object().shape({
            fullName: Yup.string()
                .trim()
                .required("validation.fullName")
                .min(3, "validation.fullNameMinMaxError")
                .max(20, "validation.fullNameMinMaxError"),
            email: Yup.string()
                .trim()
                .email("validation.validEmail")
                .required("validation.email"),
            otp: Yup.string()
                .trim()
                .required("validation.otp"),
            password: Yup.string().trim()
                .required(t("validation.password"))
                .min(8, t("validation.inValidPasswordErr"))
                .max(20, t("validation.inValidPasswordErr"))
                .matches(/\d/, t("validation.inValidPasswordErr"))
                .matches(/[A-Za-z]/, t("validation.inValidPasswordErr"))
                .strict(true),
        });
    };

    const handleDisableButtonStatus = () => {
        if (affiliatePath?.isFree) {
            const disableButton = (emailStatusRef.current === undefined  || emailStatusRef.current === false  ||passwordStatus.current === false  || dateState.current === false ||nameStatus.current === false ||mobileVerifyStatusRef.current === undefined || mobileVerifyStatusRef.current === false || otpVerifyStatusRef.current === false || formik.errors.fullName !== undefined || formik.errors.password !== undefined)
            setdisableButtonStatus(disableButton)
        } else {
            if (phoneValue === "" || passwordStatus.current === false || nameStatus.current === false || mobileVerifyStatusRef.current === undefined || mobileVerifyStatusRef.current === false || formik.errors.fullName !== undefined || formik.errors.password !== undefined || !formik.isValid) {
                setdisableButtonStatus(true)
            } else {
                setdisableButtonStatus(false)
            }
        }

    }


    const handleSubmit = () => {
        setMobileVerifyStatus(undefined)
        mobileVerifyStatusRef.current = undefined
        setOtpVerifyStatus(false)
        otpVerifyStatusRef.current = false
        setEmailStatus(undefined)
        emailStatusRef.current = undefined
        setDateRange(null)
        const newProps = { ...props, setCheckoutFormState, setLoading };
        dispatch(ynertiaPaymentResponseActions.setFreeSimsCount(1));
        checkFreeSims(affiliatePath, phoneValue, 1, dispatch, newProps, toast, t, userData, headers);
    };

    const formik = useFormik({
        initialValues: formInitialValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
        enableReinitialize: true,
    });


    const updateProfile = (type, value) => {
        if (!otpVerifyStatusRef.current) {
          return;
        }
      
        const requestObj = {
          userId: userData.userId ?? 0,
          email: formik.values.email,
        };
      
        switch (type) {
          case 'phone':
            const phone = {
              localPhoneNumber: phoneValue ? phoneValue : '',
              callingCode: formik.values?.callingCode ? formik.values?.callingCode : '',
            };
            requestObj.phone = phone;
            break;
      
          case 'travelDate':
            if (value?.length) {
              requestObj.travelDate = value;
            }
            break;
      
          case 'name':
            requestObj.firstName = formik.values?.fullName ? formik.values?.fullName.trim() : '';
            requestObj.lastName = '';
            break;
      
          default:
            break;
        }
      
        headers.sessionid = userData.sessionId;
      
        updateUserProfile(requestObj, headers, dispatch, (response) => {
          if (response.result === "SUCCESS") {
            delete requestObj.travelDate;
            switch (type) {
              case 'phone':
                setMobileVerifyStatus(true);
                mobileVerifyStatusRef.current = true
                setphoneError("");
                handleDisableButtonStatus()
                break;
      
              case 'name':
                nameStatus.current = true
                setNameError(""); 
                handleDisableButtonStatus()
                break;
      
              case 'travelDate':
                dateState.current = true
                setDateError("");
                handleDisableButtonStatus()
                break;
      
              default:
                break;
            }
            requestObj.sessionId = userData.sessionId;
            requestObj.signupType = userData.signupType;
            dispatch(authActions.onLogin(requestObj));
      
            toast.current.show({
              life: globalConfig.toastDisplayTime,
              severity: "success",
              summary: t("toastSummary.success"),
              detail: t("toast.userSuccessUpdate"),
            });
      
          } else {
            const error = response.error;
            const errorMsg = error.errorMsg ? error.errorMsg : error.summary;
      
            switch (type) {
              case 'phone':
                setMobileVerifyStatus(false);
                mobileVerifyStatusRef.current = false
                setphoneError("Phone update failed");
                handleDisableButtonStatus()
                break;
      
              case 'name':
                nameStatus.current = false
                setNameError("Name update failed")
                handleDisableButtonStatus()
                break;
      
              case 'travelDate':
                dateState.current = false
                setDateError("Travel date update failed");
                handleDisableButtonStatus()
                break;
      
              default:
                break;
            }
      
            toast.current.show({
              life: globalConfig.toastDisplayTime,
              severity: error.severity,
              summary: t("toastSummary.error"),
              detail: t(`errorMsg.${errorMsg}`),
            });
          }
        });
    };

    const handleNameBlur = (e) => {
       formik.handleBlur(e)
       handleDisableButtonStatus()
        if(2 < formik.values.fullName.length && !formik.errors.fullName) {
            updateProfile("name")
        }
    }

    const handleEmailBlur = (e) => {
        if (otpVerifyStatusRef.current) {
            return
        }
        formik.handleBlur(e)

        if (!formik.errors.email && formik.values.email) {
            let requestObj = {}
            requestObj.email = formik.values.email.trim();
            const getResponse = (response) => {
                if (response.result === 'SUCCESS') {
                    setEmailStatus(true)
                    emailStatusRef.current = true
                    setOtpShow(true)
                    toast.current.show({ life: globalConfig.toastDisplayTime, severity: 'success', summary: t("toastSummary.success"), detail: t("verifyEmail.subText") })
                } else {
                    const error = response.error;

                    //otp only one time generating we implimenting the this flow
                    if (response.error.status === 401) {
                        setEmailStatus(true)
                        emailStatusRef.current = true
                        setOtpShow(true)
                        return
                    }

                    const errorMsg = (error.errorMsg) ? error.errorMsg : error.summary;
                    toast.current.show({ life: globalConfig.toastDisplayTime, severity: error.severity, summary: t("toastSummary.error"), detail: t(`errorMsg.${errorMsg}`) })
                    setEmailStatus(false)
                    emailStatusRef.current = false
                }
            }
            signup(requestObj, headers, dispatch, getResponse);
        } 

            handleDisableButtonStatus()
        
    }
    const handlePhoneBlur = (e) => {
        formik.handleBlur(e);
        setphoneError("");
        if(phoneValue === "") {
            mobileVerifyStatusRef.current = false;
            setMobileVerifyStatus(false);
            handleDisableButtonStatus();
        } else {
            updateProfile("phone");
        }
    }
    const handlePhoneChange =(e)=>{
        if(mobileVerifyStatus || mobileVerifyStatusRef.current) {
            mobileVerifyStatusRef.current = false
            setMobileVerifyStatus(false)
            handleDisableButtonStatus();
        }
        setPhoneValue(e)
}

    const handleOtpVerifyBlur = (e) => {
        if (otpVerifyStatusRef.current) {
            return
        }
        formik.handleBlur(e)
        if (!formik.errors.otp && formik.values.otp) {
            let requestObj = {
                email: formik.values.email,
                code: formik?.values?.otp?.toString()
            }
            const getResponse = (response) => {
                if (response.result === 'SUCCESS') {
                    setOtpVerifyStatus(true)
                    otpVerifyStatusRef.current = true
                    dispatch(authActions.onLogin(response.data));
                    toast.current.show({ life: globalConfig.toastDisplayTime, severity: 'success', summary: t("toastSummary.success"), detail: t("verifyEmail.successMessage") })
                } else {
                    const error = response.error;
                    setOtpVerifyStatus(false)
                    otpVerifyStatusRef.current = false
                    if (error.errorMsg === "USRVLD-02") return
                    const errorMsg = (error.errorMsg) ? error.errorMsg : error.summary;
                    toast.current.show({ life: globalConfig.toastDisplayTime, severity: error.severity, summary: t("toastSummary.error"), detail: t(`errorMsg.${errorMsg}`) })
                }
            }
            verifyotp(requestObj, headers, dispatch, getResponse);


        } 
            handleDisableButtonStatus()

    }

    const handlePasswordFocus = () => {
        if (otpVerifyStatusRef.current === false) {
            toast.current.show({ life: globalConfig.toastDisplayTime, severity: "error", summary: t('toastSummary.error'), detail: t(`verifyEmail.header`) })
        }
    }
    const handleCalenderFocus = () => {
        if (otpVerifyStatusRef.current === false) {
            setDateError("Travelling date is required")
            toast.current.show({ life: globalConfig.toastDisplayTime, severity: "error", summary: t('toastSummary.error'), detail: t(`verifyEmail.header`) })
        }else{
            setDateError("")
        }
    }

    const handlePasswordBlur = (e) => {
        formik.handleBlur(e)
        if (!formik.errors.password && formik.values.password) {
            const requestObj = {
                userId: userData.userId || 0,
                password: "",
                newPassword: formik.values.password
            }
            const getResponse = (response) => {
                if (response.result === 'SUCCESS') {
                    formik.setFieldError('password', undefined);
                    toast.current.show({ life: globalConfig.toastDisplayTime, severity: 'success', summary: t('toastSummary.success'), detail: t('toast.passwordSuccessUpdate') })
                    passwordStatus.current = true
                } else {
                    const error = response.error;
                    passwordStatus.current = false
                    formik.setFieldError('password', "Password update failed");
                    const errorMsg = (error.errorMsg) ? error.errorMsg : error.summary;
                    toast.current.show({ life: globalConfig.toastDisplayTime, severity: error.severity, summary: t('toastSummary.error'), detail: t(`errorMsg.${errorMsg}`) })
                }
                handleDisableButtonStatus();
            };
            headers.sessionid = userData.sessionId;
            changeUserPassword(requestObj, headers, dispatch, getResponse);
        } else {
            handleDisableButtonStatus();
        }
    };
    

    const handleResendOtp = () => {
        setSeconds(30);
        setIsCounting(true);
        const requestObj = {
            email: formik.values.email,
            device: "string"
        }
        const getResponse = (response) => {
            if (response.result === 'SUCCESS') {
                toast.current.show({ life: globalConfig.toastDisplayTime, severity: 'success', summary: t('toastSummary.success'), detail: "Otp Resent" })
            } else {
                const error = response.error;
                const errorMsg = (error.errorMsg) ? error.errorMsg : error.summary;
                toast.current.show({ life: globalConfig.toastDisplayTime, severity: error.severity, summary: t('toastSummary.error'), detail: t(`errorMsg.${errorMsg}`) })
            }
        }
        reSendOtp(requestObj, headers, dispatch, getResponse)

    }

    const handleChangeOtp = (e) => {
        if (/^\d*$/.test(e.target.value) && e.target.value.length < 7) {
            formik.handleChange(e)
        }
    }

    const handleDateRange = (e) => {
        if (otpVerifyStatusRef.current === false) {
            return
        }
        setDateRange(e.value);
        updateProfile("travelDate", e.value)
        handleDisableButtonStatus();
    }

    useEffect(() => {
        let isValidPhone = parsePhoneNumber(formik.values.phone);
        if(!phoneValue) {
            setphoneError(t("validation.phone"))
        } else if(isValidPhone?.nationalNumber.length <= 5 || isValidPhone?.nationalNumber === undefined ) {
            setphoneError(t("validation.phoneMinMaxError"))
        } else {
            setphoneError("")
        }
    }, [formik.values.phone, phoneValue, t])

    return (
        <>
            <div className="freesim-form-section">
                <div className="text-right form-top-link">
                    <p>Already have an account?</p>
                    <PR.Button className="navigationLink" onClick={() => props.setAffiliatePage("signIn")} >{t("login.login")}</PR.Button>
                </div>
                <div className="form">
                    <h3>{affiliatePath?.isFree ? 'Sign up to activate your free eSIM bundle' : 'Almost there! Give us some details to continue'}</h3>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="grid">
                            <div className={otpShow ? `col-12 lg:col-8 md:col-8` : `col-12`} >
                                <div className="input-field">
                                    <PR.InputText id="login-email" name="email" readOnly={otpVerifyStatus} onChange={formik.handleChange} onBlur={handleEmailBlur} value={formik.values.email} className="w-12" placeholder="Email address" autoComplete="off" />
                                    {emailStatus !== undefined ? <div className={emailStatus === true ? `icon success` : `icon warning`}><i className={emailStatus === true ? `pi pi-check` : `pi pi-info`}></i></div> : null}
                                    {formik.errors.email && formik.touched.email ? <div className='error-message'>{t(formik.errors.email)}</div> : ''}
                                </div>
                            </div>
                            {otpShow ? <div className="col-12 lg:col-4 md:col-4">
                                <div className="input-field">
                                    <PR.InputText id="login-email" name="otp" readOnly={otpVerifyStatus} type="text" onChange={handleChangeOtp} onBlur={handleOtpVerifyBlur} value={formik.values.otp} className="w-12" placeholder="OTP" autoComplete="off" />

                                    {otpVerifyStatus === true ? <div className={`icon success`}><i className={`pi pi-check`}></i></div> : <PR.Button label={`Resend ${seconds !== 30 && seconds !== 0 ? `(${seconds < 10 ? `0${seconds}` : seconds})` : ""}`} disabled={isCounting} onClick={handleResendOtp} className="resend-btn" />}
                                    {formik.errors.otp && formik.touched.otp ? <div className='error-message'>{t(formik.errors.otp)}</div> : ''}
                                </div>
                            </div> : null}
                        </div>
                        <div className="grid">
                            <div className="col-12">
                                <div className="input-field">
                                    <div className="flex flex-column" >
                                        <CustomPhoneNumber handlePhoneBlur={handlePhoneBlur} readOnly={!otpVerifyStatus || loading} phoneError={phoneError} formik={formik} t={t} setPhoneValue={handlePhoneChange}/>
                                        {mobileVerifyStatus !== undefined ? <div className={mobileVerifyStatus === true ? `icon success` : `icon warning`}><i className={mobileVerifyStatus === true ? `pi pi-check` : `pi pi-info`}></i></div> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid">
                            <div className="col-12">
                                <div className="input-field">
                                    <PR.InputText id="fullName" name="fullName" onChange={formik.handleChange} readOnly={!otpVerifyStatus || loading} onFocus={handlePasswordFocus} onBlur={handleNameBlur} value={formik.values.fullName} className="w-12" placeholder="Enter your full name" autoComplete="off" />
                                    {formik.errors.fullName && formik.touched.fullName ? <div className='error-message'>{t(formik.errors.fullName)}</div> : ''}
                                    {nameError ? <div className='error-message'>{t(nameError)}</div> : ''}
                                </div>
                            </div>
                        </div>
                        <div className="grid">
                            <div className={affiliatePath?.isFree ? `col-12 lg:col-7 md:col-7` : `col-12`} >
                                <div className="input-field">
                                    <PR.Password feedback={false} id="password" name="password" onChange={formik.handleChange} readOnly={!otpVerifyStatus || loading} onFocus={handlePasswordFocus} onBlur={handlePasswordBlur} value={formik.values.password} placeholder="Set your password" className="w-12" toggleMask autoComplete="off" />
                                    {formik.errors.password && formik.touched.password ? <div className='error-message'>{t(formik.errors.password)}</div> : ''}
                                </div>
                            </div>
                              {affiliatePath?.isFree ? <div className="col-12 lg:col-5 md:col-5">
                                <div className="input-field">
                                    <PR.Calendar name='dateRange' dateFormat="dd/mm/yy" value={dateRange} showIcon={otpVerifyStatus && !loading} showOnFocus={otpVerifyStatus && !loading} onFocus={handleCalenderFocus} readOnlyInput onChange={handleDateRange} selectionMode="range" hideOnRangeSelection placeholder="When are you travelling?" />
                                    {dateError ? <div className='error-message'>{t(dateError)}</div> : ''}
                                </div>
                            </div> : null}
                        </div>
                        <PR.Button label={!loading ? affiliatePath.isFree ? "Get your free eSIM" : "Continue to Payment" : "Loading..."} disabled={disableButtonStatus || loading} type='submit' className="login-btn" />
                        <span className="or-span">or</span>
                        <div className="flex align-items-center justify-content-center gap-3 mobile-flex">
                            <GoogleLoginComponent displayName={t("login.googleSignUp")} setAffiliatePage={props.setAffiliatePage} setCheckoutFormState={setCheckoutFormState} setLoading={props.setLoading}/>
                            <AppleLoginComponent displayName={t("login.appleSignUp")} setAffiliatePage={props.setAffiliatePage} setCheckoutFormState={setCheckoutFormState} setLoading={props.setLoading}/>
                        </div>
                    </form>
                    <PR.Toast ref={toast} position='top-right' />
                    <div className="flex  checkbox">
                        <label htmlFor="account">By clicking Get your free eSIM, I agree that I have read and accepted the <Link to="/terms-and-conditions" target="_blank">Terms of Service</Link> and <Link to="/privacy-policy" target="_blank">Privacy Policy</Link>.</label>
                    </div>
                </div>
            </div>
            <PaymentModal checkoutFormState={checkoutFormState} setCheckoutFormState={setCheckoutFormState} affiliatePath={affiliatePath} setAffiliatePage={props?.setAffiliatePage}/>
        </>
    );
}

export default GetFreeSim;