import { createSlice } from '@reduxjs/toolkit';

const getcatalogCart = localStorage.getItem('CART');
const getcatalogCartAmount = localStorage.getItem('CART-AMOUNT');
const getrandomId = localStorage.getItem('RANDOM-ID');

const catalogCart = (getcatalogCart === 'undefined' || getcatalogCart === "") ? JSON.stringify(getcatalogCart) : (getcatalogCart !== null) ? JSON.parse(getcatalogCart): {};
const catalogCartAmount = (getcatalogCartAmount === 'undefined' || getcatalogCartAmount === "") ? JSON.stringify(getcatalogCartAmount) : (getcatalogCartAmount !== null) ? JSON.parse(getcatalogCartAmount): 0;
const randomId = (getrandomId === 'undefined' || getrandomId === "") ? JSON.stringify(getrandomId) : (getrandomId !== null) ? JSON.parse(getrandomId): 0;

const initialCartFilter = {
    catalogCartAmount: catalogCartAmount,
    catalogCart: catalogCart,
    randomId
}

export const cartFilterSlice = createSlice({
    name: 'catalogCart',
    initialState: initialCartFilter,
    reducers: {
        setCatalogCart(state, action) {
            state.catalogCart = action.payload
            localStorage.setItem("CART", JSON.stringify(action.payload));
        }, 
        setCatalogCartAmount(state, action) {
            state.catalogCartAmount = action.payload
            localStorage.setItem("CART-AMOUNT", JSON.stringify(action.payload));
        },
        setRandomIdForPayment(state, action) {
          state.randomId = action.payload
          localStorage.setItem("RANDOM-ID", JSON.stringify(action.payload))  
        },
        removeRandomIdInPayment(state) {
            state.randomId = "";
            localStorage.removeItem('RANDOM-ID');
        },
        deleteCatalogCart(state) {
            state.catalogCart = {}
            state.catalogCartAmount = 0;
            localStorage.removeItem('CART');
            localStorage.removeItem('CART-AMOUNT');
        }
    },
})

export const cartFilterActions = cartFilterSlice.actions;
export default cartFilterSlice.reducer;