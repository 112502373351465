import { useFormik } from 'formik';
import React, { useMemo, useState } from 'react';
import * as PR from '../prime-modules/index';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { displayDefaultPhone, handleFirstNameChange, handleLastNameChange, trimFormData } from '../utils/reuse';
import { contact } from '../services/api';
import CustomPhoneNumber from './CustomPhoneNumber';
import { getPhoneObj } from '../shared/methods/GetAllCountries';
import { useToast } from '../context/ToastContext';

const ContactForm = ({formType}) => {
    const {validations,showToast, labels} = useToast()
    const dispatch = useDispatch();
    const isAuth = useSelector((state) => state.auth.isLoggedIn);
    const userData = useSelector((state) => state.auth.sessionData);
    const getDefaultCallingCode = useSelector((state) => state.allCountries.defaultCallingCode);
    const [phoneValue, setPhoneValue] = useState('');
    const affiliateTrackingData = useSelector(
        (state) => state.affiliateTracking.affiliateTrackingInfo
    );
    const headers = useMemo(() => {
        return { afid: affiliateTrackingData };
    }, [affiliateTrackingData]);

    const formInitialValues = {
        firstName: isAuth ? userData?.firstName : "",
        lastName: isAuth ? userData?.lastName : "",
        email: isAuth ? userData?.email : "",
        phone: displayDefaultPhone(isAuth, userData, getDefaultCallingCode),
        body: "",
        type: formType,
    };

    const validationSchema = () => {
        return Yup.object().shape({
            firstName: Yup.string().trim()
                .required(validations?.VLD0007)
                .min(3, validations?.VLD0008)
                .max(20, validations?.VLD0008),
            email: Yup.string().trim()
                .email(validations?.VLD0006)
                .required(validations?.VLD0005),
            body: Yup.string().trim().required(validations?.VLD0015),
        });
    };

    const handleSubmit = (formData) => {
        const trimmedFormData = trimFormData(formData);
        const requestObj = { ...trimmedFormData }
        requestObj.phone = getPhoneObj(phoneValue, isAuth, userData, formData);
        delete requestObj.callingCode;
        const faqResponse = (response) => {
            if (response.result === "SUCCESS") {
                formik.resetForm();
                setPhoneValue('');
                showToast("success",labels?.LBL0281)
                setTimeout(() => {
                }, [3000])
            } else {
                const error = response.error;
                const errorMsg = error.errorMsg ? error.errorMsg : error.summary;
                showToast("error",errorMsg)
            }
        };
        contact(requestObj, headers, dispatch, faqResponse);
    };

    const formik = useFormik({
        initialValues: formInitialValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
        enableReinitialize: true,
    });

    const isSubmitDisabled = () => {
        return !(formik.values.firstName && formik.values.email && formik.values.body && formik.isValid);
    };

    return (
        <>
            <form
                onSubmit={formik.handleSubmit}
                autoComplete="off"
                className="about_form"
            >
                <div className="grid mb-2">
                    <div className="col-12 lg:col-6 md:col-6">
                        <div className="flex flex-column">
                            <label htmlFor="firstName">{labels?.LBL0001}</label>
                            <span className="p-input-icon-right">
                                <PR.InputText id="firstName" name="firstName" placeholder={labels?.LBL0001} className="w-12" keyfilter={"alphanum"} value={formik.values.firstName} onChange={(e) => handleFirstNameChange(e, formik)} onBlur={formik.handleBlur} autoComplete="off" />
                                {formik.errors.firstName && formik.touched.firstName ? <div className='error-message'>{formik.errors.firstName}</div> : ''}
                            </span>
                        </div>
                    </div>
                    <div className="col-12 lg:col-6 md:col-6">
                        <div className="flex flex-column">
                            <label htmlFor="lastName">{labels?.LBL0002}</label>
                            <span className="p-input-icon-right">
                                <PR.InputText id="lastName" name="lastName" placeholder={labels?.LBL0003} className="w-12" keyfilter={"alphanum"} value={formik.values.lastName} onChange={(e) => handleLastNameChange(e, formik)} onBlur={formik.handleBlur} autoComplete="off" />
                                {formik.errors.lastName && formik.touched.lastName ? <div className='error-message'>{formik.errors.lastName}</div> : ''}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="grid mb-2">
                    <div className="col-12 lg:col-6 md:col-6">
                        <div className="flex flex-column">
                            <label htmlFor="email">{labels?.LBL0010}</label>
                            <span className="p-input-icon-right">
                                <PR.InputText id="email" name="email" placeholder={labels?.LBL0010} className="w-12" value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} autoComplete="off" />
                                {formik.errors.email && formik.touched.email ? <div className='error-message'>{formik.errors.email}</div> : ''}
                            </span>
                        </div>
                    </div>

                    <div className="col-12 lg:col-6 md:col-6">
                        <div className="flex flex-column">
                            <label htmlFor="phone">{labels?.LBL0011}</label>
                            <CustomPhoneNumber formik={formik} setPhoneValue={setPhoneValue} id="phone"/>
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12">
                        <div className="flex flex-column">
                            <label htmlFor="body">{labels?.LBL0012}</label>
                            <span className="p-input-icon-right">
                                <PR.InputTextarea rows={2} cols={30} maxLength={500} autoResize className="w-12" id="body" name="body" placeholder={labels?.LBL0013} value={formik.values.body} onChange={formik.handleChange} onBlur={formik.handleBlur} autoComplete="off" />
                                {formik.errors.body && formik.touched.body ? <div className='error-message'>{formik.errors.body}</div> : ''}
                            </span>
                        </div>
                    </div>
                    <PR.Button
                        className="goto-plans-button p-button-rounded"
                        type="submit" label={labels?.LBL0014}
                        disabled={isSubmitDisabled()}
                    />
                </div>
            </form>
        </>
    );
}

export default ContactForm;