import React from 'react'
import { Link } from "react-router-dom";

import * as PR from "../../prime-modules/index";
import "./freeEsimDetails/FreeEsimDetails.scss";


const ExistingUser = (props) => {

  const checkoutOrderHandler = () => {
    props?.setCheckoutFormState(true);
};

  return (
    <div className="verify-phonenumber-section">
      <div className="form-section">
        <div className="form">
          <h3>Uh-Oh!</h3>
          <p >You have exceeded number of free bundles. Please click below to top up your eSIM.</p>
          <PR.Button label="Continue to Purchase" onClick={checkoutOrderHandler} className="login-btn" />
          <p className="mt-3">Facing any issue? <Link target="_blank" to="/contact">Contact Support</Link></p>
        </div>
      </div>
    </div>
  )
}

export default ExistingUser