import React from "react";
import "../terms-and-conditions/TermsAndConditions.scss";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { globalConfig } from "../../constants";
import SubHeader from "../../components/SubHeader";
import SupportMailsSection from "../../components/SupportMailsSection";
import { useToast } from '../../context/ToastContext'

const TermsAndConditions = () => {
    const { labels, general } = useToast();

  return (
     <HelmetProvider> 
      <Helmet>
          <meta charSet="utf-8" />
          <title>{general.GEN0001}| {labels.LBL0322}</title>
      </Helmet>

      {/* Header Section */}
      <SubHeader header={labels.LBL0322} subText={labels.LBL0323} />

      <section className="about-blocks-section tc-block">
        <div className="terms-and-conditions-section">
            <p>This document on Website Use Terms and Conditions, together with any and all other documents referred to herein, set out the terms of use under which you may use this website, <a href={globalConfig.domainUrl} rel="canonical">{globalConfig.domainUrl}</a> ("Our Site"). Please read these Terms and Conditions carefully and ensure that you understand them. Your agreement to comply with and be bound by these Terms and Conditions is deemed to occur upon your first use of Our Site. If you do not agree to comply with and be bound by these Terms and Conditions, you must stop using Our Site immediately. These Terms and Conditions are in the English language only.</p>
            <ol className="sub-number-ol">
                <li>
                    <span className="heading">Definitions and Interpretation</span>
                    <ol>
                        <li>In these Terms and Conditions, unless the context otherwise requires, the following expressions have the following meanings:</li>
                    </ol>
                    <p><b>"Account"</b>: means an account required for a User to access and/or use certain areas of Our Site, as detailed in Clause 4;</p>
                    <p><b>"Content"</b>: means any and all text, images, audio, video, scripts, code, software, databases and any other form of information capable of being stored on a computer that appears on, or forms part of, Our Site; and</p>
                    <p><b>"We/Us/Our"</b>: <a href={globalConfig.domainUrl} rel="canonical">{globalConfig.domainUrl}</a> </p>
                </li>
                <li>
                    <span className="heading">Information About Us</span>
                    <ol>
                        <li>Our Site is owned and operated by DATA2GO LIMITED., a limited company registered in England under company number <b>13154302</b> whose registered office is at <b>115c, Milton Road, CAMBRIDGE CB4 1XE, ENGLAND</b>.</li>
                    </ol>
                </li>
                <li>
                    <span className="heading">Access to Our Site</span>
                    <ol>
                        <li>Access to Our Site is free of charge.</li>
                        <li>It is your responsibility to make any and all arrangements necessary in order to access Our Site.</li>
                        <li>Access to Our Site is provided "as is" and on an "as available" basis. We may alter, suspend or discontinue Our Site (or any part of it) at any time and without notice. We will not be liable to you in any way if Our Site (or any part of it) is unavailable at any time and for any period.</li>
                    </ol>
                </li>
                <li>
                    <span className="heading">Accounts</span>
                    <ol>
                        <li>Certain parts of Our Site (including the ability to purchase goods from Us) may require an Account in order to access them.</li>
                        <li>You may not create an Account if you are under 18 years of age.</li>
                        <li>When creating an Account, the information you provide must be accurate and complete. If any of your information changes at a later date, it is your responsibility to ensure that your Account is kept up-to-date.</li>
                        <li>We recommend that you choose a strong password for your Account. It is your responsibility to keep your password safe. You must not share your Account with anyone else. If you believe your Account is being used without your permission, please contact us immediately at <a href={`mailto:${globalConfig.supportEmail}`} target='_top' rel="noreferrer">{globalConfig.supportEmail}</a>. We will not be liable for any unauthorised use of your Account.</li>
                        <li>You must not use anyone else's Account.</li>
                        <li>Any personal information provided in your Account will be collected, used, and held in accordance with your rights and Our obligations under the law, as set out in Clause 16.</li>
                        <li>If you wish to close your Account, you may do so at any time. Closing your Account will result in the removal of your information. Closing your Account will also remove access to any areas of Our Site requiring an Account for access.</li>
                    </ol>
                </li>
                <li>
                    <span className="heading">Intellectual Property Rights</span>
                    <ol>
                        <li>All Content included on Our Site and the copyright and other intellectual property rights subsisting in that Content, unless specifically labelled otherwise, belongs to or has been licensed by Us. All Content is protected by applicable United Kingdom and international intellectual property laws and treaties.</li>
                        <li>Subject to sub-Clauses 5.3 and 5.6 you may not reproduce, copy, distribute, sell, rent, sub-license, store, or in any other manner re-use Content from Our Site unless given express written permission to do so by Us.</li>
                        <li>You may:
                            <ol>
                                <li>Access, view and use Our Site in a web browser (including any web browsing capability built into other types of software or app);</li>
                                <li>Download Our Site (or any part of it) for caching;</li>
                                <li>Print one copy of any of any pages from Our Site;</li>
                                <li>Download extracts from pages on Our Site; and</li>
                                <li>Save pages from Our Site for later and/or offline viewing.</li>
                            </ol>
                        </li>
                        <li>Our status as the owner and author of the Content on Our Site (or that of identified licensors, as appropriate) must always be acknowledged.</li>
                        <li>You may not use any Content saved or downloaded from Our Site for commercial purposes without first obtaining a license from Us (or our licensors, as appropriate) to do so. This does not prohibit the normal access, viewing and use of Our Site for general information purposes whether by business users or consumers.</li>
                        <li>Nothing in these Terms and Conditions limits or excludes the provisions of Chapter III of the Copyrights, Designs and Patents Act 1988  'Acts Permitted in Relation to Copyright Works', covering in particular the making of temporary copies; research and private study; the making of copies for text and data analysis for non-commercial research; criticism, review, quotation and news reporting; caricature, parody or pastiche; and the incidental inclusion of copyright material.</li>
                    </ol>
                </li>
                      <li>
                          <span className="heading">Fair usage policy</span>
                          <p>
                              For all Unlimited Data Plans, data2go implement a fair usage policy; after 1GB has been used at full speed, the data speed will run at 512 kbps. The Data speed resets every 24 hours depending on when the Data Plan was first activated and the first 1GB will run at full speed after each reset.
                          </p>
                      </li>
                <li>
                    <span className="heading">Links to Our Site</span>
                    <ol>
                        <li>You may link to Our Site provided that:
                            <ol>
                                <li>You do so in a fair and legal manner;</li>
                                <li>You do not do so in a manner that suggests any form of association, endorsement or approval on Our part where none exists;</li>
                                <li>You do not use any logos or trademarks displayed on Our Site without Our express written permission; and</li>
                                <li>You do not do so in a way that is calculated to damage Our reputation or to take unfair advantage of it.</li>
                            </ol>
                        </li>
                        <li>You may not link to Our Site from any other site the main content of which contains material that:
                            <ol>
                                <li>is sexually explicit;</li>
                                <li>is obscene, deliberately offensive, hateful or otherwise inflammatory;</li>
                                <li>promotes violence;</li>
                                <li>promotes or assists in any form of unlawful activity;</li>
                                <li>discriminates against, or is in any way defamatory of, any person, group or class of persons, race, sex, religion, nationality, disability, sexual orientation, or age;</li>
                                <li>is intended or is otherwise likely to threaten, harass, annoy, alarm, inconvenience, upset, or embarrass another person;</li>
                                <li>is calculated or is otherwise likely to deceive another person;</li>
                                <li>is intended or otherwise likely to infringe (or threaten to infringe) another person's right to privacy;</li>
                                <li>misleadingly impersonates any person or otherwise misrepresents the identity or affiliation of a particular person in a way that is calculated to deceive (obvious parodies are not included in this definition provided that they do not fall within any of the other provisions of this sub-Clause 6.4);</li>
                                <li>implies any form of affiliation with Us where none exists;</li>
                                <li>infringes, or assists in the infringement of, the intellectual property rights (including, but not limited to, copyright, trademarks and database rights) of any other party; or</li>
                                <li>is made in breach of any legal duty owed to a third party including, but not limited to, contractual duties and duties of confidence.</li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>
                    <span className="heading">Links to Other Sites</span>
                    <p>Links to other sites may be included on Our Site. Unless expressly stated, these sites are not under Our control. We neither assume nor accept responsibility or liability for the content of third-party sites. The inclusion of a link to another site on Our Site is for information only and does not imply any endorsement of the sites themselves or of those in control of them.</p>
                </li>
                <li>
                    <span className="heading">Disclaimers</span>
                    <ol>
                        <li>Nothing on Our Site constitutes advice on which you should rely. It is provided for general information purposes only.</li>
                        <li>Insofar as is permitted by law, we make no representation, warranty, or guarantee that Our Site will meet your requirements, that it will not infringe the rights of third parties, that it will be compatible with all software and hardware, or that it will be secure.</li>
                        <li>We make reasonable efforts to ensure that the Content on Our Site is complete, accurate, and up-to-date. We do not, however, make any representations, warranties or guarantees (whether express or implied) that the Content is complete, accurate, or up-to-date.</li>
                    </ol>
                </li>
                <li>
                    <span className="heading">Our Liability</span>
                    <ol>
                        <li>To the fullest extent permissible by law, We accept no liability to any user for any loss or damage, whether foreseeable or otherwise, in contract, tort (including negligence), for breach of statutory duty, or otherwise, arising out of or in connection with the use of (or inability to use) Our Site or the use of or reliance upon any Content included on Our Site.</li>
                        <li>To the fullest extent permissible by law, We exclude all representations, warranties, and guarantees (whether express or implied) that may apply to Our Site or any Content included on Our Site.</li>
                        <li>If you are a business user, We accept no liability for loss of profits, sales, business or revenue; loss of business opportunity, goodwill or reputation; loss of anticipated savings; business interruption; or for any indirect or consequential loss or damage.</li>
                        <li>We exercise all reasonable skill and care to ensure that Our Site is free from viruses and other malware. We accept no liability for any loss or damage resulting from a virus or other malware, a distributed denial of service attack, or other harmful material or event that may adversely affect your hardware, software, data or other material that occurs as a result of your use of Our Site (including the downloading of any Content from it) or any other site referred to on Our Site.</li>
                        <li>We neither assume nor accept responsibility or liability arising out of any disruption or non-availability of Our Site resulting from external causes including, but not limited to, ISP equipment failure, host equipment failure, communications network failure, natural events, acts of war, or legal restrictions and censorship.</li>
                        <li>Nothing in these Terms and Conditions excludes or restricts Our liability for fraud or fraudulent misrepresentation, for death or personal injury resulting from negligence, or for any other forms of liability which cannot be excluded or restricted by law. For full details of consumers' legal rights, including those relating to digital content, please contact your local Citizens' Advice Bureau or Trading Standards Office.</li>
                    </ol>
                </li>
                <li>
                    <span className="heading">Viruses, Malware and Security</span>
                    <ol>
                        <li>We exercise all reasonable skill and care to ensure that Our Site is secure and free from viruses and other malware.</li>
                        <li>You are responsible for protecting your hardware, software, data and other material from viruses, malware, and other internet security risks.</li>
                        <li>You must not deliberately introduce viruses or other malware, or any other material which is malicious or technologically harmful either to or via Our Site.</li>
                        <li>You must not attempt to gain unauthorised access to any part of Our Site, the server on which Our Site is stored, or any other server, computer, or database connected to Our Site.</li>
                        <li>You must not attack Our Site by means of a denial of service attack, a distributed denial of service attack, or by any other means.</li>
                        <li>By breaching the provisions of sub-Clauses 10.3 to 10.5 you may be committing a criminal offense under the Computer Misuse Act 1990. Any and all such breaches will be reported to the relevant law enforcement authorities and We will cooperate fully with those authorities by disclosing your identity to them. Your right to use Our Site will cease immediately in the event of such a breach.</li>
                    </ol>
                </li>
                <li>
                    <span className="heading">Acceptable Usage Policy</span>
                    <ol>
                        <li>You may only use Our Site in a manner that is lawful. Specifically:
                            <ol>
                                <li>you must ensure that you comply fully with any and all local, national or international laws and/or regulations</li>
                                <li>you must not use Our Site in any way, or for any purpose, that is unlawful or fraudulent;</li>
                                <li>you must not use Our Site to knowingly send, upload, or in any other way transmit data that contains any form of virus or other malware, or any other code designed to adversely affect computer hardware, software, or data of any kind; and</li>
                                <li>you must not use Our Site in any way, or for any purpose, that is intended to harm any person or persons in any way.</li>
                            </ol>
                        </li>
                        <li>We reserve the right to suspend or terminate your access to Our Site if you materially breach the provisions of this Clause 11 or any of the other provisions of these Terms and Conditions. Specifically, We may take one or more of the following actions:
                            <ol>
                                <li>suspend, whether temporarily or permanently, your right to access Our Site;</li>
                                <li>issue you with a written warning;</li>
                                <li>take legal proceedings against you for reimbursement of any and all relevant costs on an indemnity basis resulting from your breach;</li>
                                <li>take further legal action against you as appropriate;</li>
                                <li>disclose such information to law enforcement authorities as required or as We deem reasonably necessary; and/or</li>
                                <li>any other actions which We deem reasonably appropriate (and lawful).</li>
                            </ol>
                        </li>
                        <li>We hereby exclude any and all liability arising out of any actions (including, but not limited to those set out above) that We may take in response to breaches of these Terms and Conditions.</li>
                    </ol>
                </li>
                <li>
                    <span className="heading">Privacy and Cookies</span>
                    <p>Use of Our Site is also governed by Our Cookie and Privacy Policies, available from <a href="/cookie-policy" rel="canonical">Cookie Policy</a> and <a href="/privacy-policy" rel="canonical">Privacy Policy</a>. These policies are incorporated into these Terms and Conditions by this reference.</p>
                </li>
                <li>
                    <span className="heading">Changes to these Terms and Conditions</span>
                    <ol>
                        <li>We may alter these Terms and Conditions at any time. Any such changes will become binding on you upon your first use of Our Site after the changes have been implemented. You are therefore advised to check this page from time to time.</li>
                        <li>In the event of any conflict between the current version of these Terms and Conditions and any previous version(s), the provisions current and in effect shall prevail unless it is expressly stated otherwise.</li>
                    </ol>
                </li>
                <li>
                    <span className="heading">Contacting Us</span>
                    <p>To contact us, please send an email to <a href={`mailto:${globalConfig.supportEmail}`} target='_top' rel="noreferrer">{globalConfig.supportEmail}</a>.</p>
                </li>
                <li>
                    <span className="heading">Communications from Us</span>
                    <ol>
                        <li>If We have your contact details, We may from time to time send you important notices by email. Such notices may relate to matters including, but not limited to, service changes and changes to these Terms and Conditions.</li>
                        <li>We will never send you marketing emails of any kind without your express consent. If you do give such consent, you may opt out at any time. Any and all marketing emails sent by Us include an unsubscribe link. If you opt out of receiving emails from Us at any time, it may take up to 14 business days for Us to comply with your request. During that time, you may continue to receive emails from Us.</li>
                        <li>For questions or complaints about communications from Us (including, but not limited to marketing emails), please contact Us at <a href={`mailto:${globalConfig.supportEmail}`} target='_top' rel="noreferrer">{globalConfig.supportEmail}</a></li>
                        <li>To check frequently asked questions, visit  <a href="/faq" rel="canonical">FAQ</a>.</li>
                    </ol>
                </li>
                <li>
                    <span className="heading">Data Protection</span>
                    <ol>
                        <li>All personal information that We may use will be collected, processed, and held in accordance with the provisions of EU Regulation 2016/679 General Data Protection Regulation ("GDPR") and your rights under the GDPR.</li>
                        <li>For complete details of Our collection, processing, storage, and retention of personal data including, but not limited to, the purpose(s) for which personal data is used, the legal basis or bases for using it, details of your rights and how to exercise them, and personal data sharing (where applicable), please refer to Our <a href="/privacy-policy" rel="canonical">Privacy Policy</a> and <a href="/cookie-policy" rel="canonical">Cookie Policy</a>.</li>
                    </ol>
                </li>
                <li>
                    <span className="heading">Law and Jurisdiction</span>
                    <ol>
                        <li>These Terms and Conditions, and the relationship between you and Us (whether contractual or otherwise) shall be governed by, and construed in accordance with the law of England & Wales.</li>
                        <li>If you are a consumer, you will benefit from any mandatory provisions of the law in your country of residence. Nothing in Sub-Clause 17.1 above takes away or reduces your rights as a consumer to rely on those provisions.</li>
                        <li>If you are a consumer, any dispute, controversy, proceedings or claim between you and Us relating to these Terms and Conditions, or the relationship between you and Us (whether contractual or otherwise) shall be subject to the jurisdiction of the courts of England, Wales, Scotland, or Northern Ireland, as determined by your residency.</li>
                        <li>If you are a business, any disputes concerning these Terms and Conditions, the relationship between you and Us, or any matters arising therefrom or associated therewith (whether contractual or otherwise) shall be subject to the non exclusive jurisdiction of the courts of England & Wales.</li>
                    </ol>
                </li>
            </ol>
        </div>
      </section>

      <section className="inner-header-section aboutus-header-section support-section">
        <SupportMailsSection/>
      </section>
    </HelmetProvider>
  );
};

export default TermsAndConditions;