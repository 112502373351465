import { Link } from "react-router-dom";
import { globalConfig } from "../constants";
import { useToast } from '../context/ToastContext';

const SupportMailsSection = () => {
  const {labels} = useToast()
  return (
    <section className="support-mails-section">
    <div className="custom-container">
        <div className="grid grid-nogutter contact-info-block">
            <div className="col-12 lg:col-4 md:col-4">
            <h3>{labels?.LBL0218}</h3>
            <p>
                {labels?.LBL0244} <br />
                {labels?.LBL0245}
            </p>
            <Link to={`mailto:${globalConfig.supportEmail}`}>
                {globalConfig.supportEmail}
            </Link>
            </div>
            <div className="col-12 lg:col-4 md:col-4">
            <h3>{labels?.LBL0214}</h3>
            <p>
            {labels?.LBL0244} <br />
            {labels?.LBL0245}
            </p>
            <Link to={`mailto:${globalConfig.salesEmail}`}>
                {globalConfig.salesEmail}
            </Link>
            </div>
            <div className="col-12 lg:col-4 md:col-4">
            <h3>{labels?.LBL0246}</h3>
            <p>
            {labels?.LBL0250}-{labels?.LBL0248} {labels?.LBL0247} 8am {labels?.LBL0249} <br />
                5pm.
            </p>
            <span><Link to={`tel:${globalConfig.phoneNumber}`}>{globalConfig.phoneNumber}</Link> </span>
            </div>
        </div>
    </div>
    </section>
  );
};

export default SupportMailsSection;
