import { createSlice } from '@reduxjs/toolkit';
import messagesData from '../assets/data/assets.json'

const initialMessageData = {cms: messagesData};
export const messageCatalogSlice = createSlice({
    name: 'catalogIds',
    initialState: initialMessageData,
    reducers: {
        storeAll(state, action){
            state.cms = action.payload;
        },
        storeMessageCatalog(state, action) {
            state.messageCatalog = action.payload.messageCatalog;
        },
        storeLabels(state, action) {
            state.labels = action.payload.labels;
        },
        storeValidations(state, action) {
            state.validations = action.payload.validations;
        },
        storeMyAccount(state, action) {
            state.myAccount = action.payload.myAccount;
        },
        storeAboutUs(state, action) {
            state.aboutUs = action.payload.aboutUs;
        },
        storeGeneral(state, action) {
            state.general = action.payload.general;
        },
        storeThemeColors(state, action) {
            state.themeColors = action.payload.themeColors;
        },
        storeHome(state, action) {
            state.home = action.payload.home;
        },
        storeContact(state, action) {
            state.contact = action.payload.contact;
        },
        storeHeader(state, action) {
            state.header = action.payload.header;
        },
        storePartner(state, action) {
            state.partner = action.payload.partner;
        },
        storeAffiliate(state, action) {
            state.affiliate = action.payload.affiliate;
        }
    },
})

export const messageCatalogActions = messageCatalogSlice.actions;
export default messageCatalogSlice.reducer;
