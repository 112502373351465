import { createSlice } from '@reduxjs/toolkit';

const getinitialYnertiaPaymentResponse = localStorage.getItem('YNERTIA-PAYMENT-RESPONSE');
const getAffiliateScreen = localStorage.getItem('AFFILIATE-SCREEN');
const getFreeSimsCount = localStorage.getItem('FREESIMS-COUNT');

const initialYnertiaPaymentResponse = (getinitialYnertiaPaymentResponse === 'undefined' || getinitialYnertiaPaymentResponse === "") ? JSON.stringify(getinitialYnertiaPaymentResponse) : (getinitialYnertiaPaymentResponse !== null) ? JSON.parse(getinitialYnertiaPaymentResponse) : {};

const initialAffiliateScreen = (getAffiliateScreen === 'undefined' || getAffiliateScreen === "") ? getAffiliateScreen : (getAffiliateScreen !== null) ? getAffiliateScreen : 'signUp';

const initialFreeSimsCount = (getFreeSimsCount === 'undefined' || getFreeSimsCount === "") ? getFreeSimsCount : (getAffiliateScreen !== null) ? getFreeSimsCount : 0;

const initialValues = {
    affiliateScreen: initialAffiliateScreen,
    freeSimsCount: initialFreeSimsCount,
    ynertiaPaymentResponse: initialYnertiaPaymentResponse
}

export const ynertiaPaymentResponseSlice = createSlice({
    name: 'ynertiaPaymentResponse',
    initialState: initialValues,
    reducers: {
        setAffiliateScreen(state, action) {
            state.affiliateScreen = action.payload;
            localStorage.setItem("AFFILIATE-SCREEN", action.payload);
        },
        setFreeSimsCount(state, action) {
            state.freeSimsCount = action.payload;
            localStorage.setItem("FREESIMS-COUNT", action.payload);
        },
        setYnertiaPaymentResponse(state, action) {
            state.ynertiaPaymentResponse = action.payload;
            localStorage.setItem("YNERTIA-PAYMENT-RESPONSE", JSON.stringify(action.payload));
        },
        setBundleDetails(state, action) {
            state.bundleDetails = action.payload;
            localStorage.setItem("YNERTIA-BUNDLE-DETAILS", JSON.stringify(action.payload));
        },
        deleteYnertiaPaymentResponse(state) {
            state.ynertiaPaymentResponse = '';
            state.freeSimsCount = '';
            state.affiliateScreen = 'signUp'
            localStorage.removeItem('YNERTIA-PAYMENT-RESPONSE');
            localStorage.removeItem('AFFILIATE-SCREEN');
            localStorage.removeItem('FREESIMS-COUNT');
        }
    },
})

export const ynertiaPaymentResponseActions = ynertiaPaymentResponseSlice.actions;
export default ynertiaPaymentResponseSlice.reducer;
