import React, { useRef, useMemo } from 'react';
import * as PR from "../../../prime-modules/index";
import { forgotPassword } from '../../../services/api';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from "formik";
import { globalConfig } from '../../../constants';
import { useTranslation } from 'react-i18next';

const EsimForgotPassword = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const toast = useRef();
    const affiliatePath = useSelector(state => state.auth.affiliatePath);
    const affiliateTrackingData = useSelector(state => state.affiliateTracking.affiliateTrackingInfo ? state.affiliateTracking.affiliateTrackingInfo : affiliatePath?.afid);
    const headers = useMemo(() => {
        return { afid: affiliateTrackingData };
    }, [affiliateTrackingData]);

    const validationSchema = () => {
        return Yup.object().shape({
            email: Yup.string()
                .trim()
                .email("validation.validEmail")
                .required("validation.email"),
        });
    }
    const submitHandler = (formData) => {

        const getResponse = (response) => {
            if (response.result === "SUCCESS") {
                toast.current.show({ life: globalConfig.toastDisplayTime, severity: 'success', summary: t("toastSummary.success"), detail: t("forgotPwd.successMessage") });
                formik.resetForm();
            } else {
                const error = response.error;
                const errorMsg = (error.errorMsg) ? error.errorMsg : error.summary;
                toast.current.show({ life: globalConfig.toastDisplayTime, severity: error.severity, summary: t("toastSummary.error"), detail: t(`errorMsg.${errorMsg}`) })
            }

        }
        const emailVal = formData.email.trim();
        forgotPassword(`forgotpassword?email=${emailVal}`, headers, dispatch, getResponse)
    }
    const formik = useFormik({
        initialValues: { email: "" },
        validationSchema: validationSchema,
        onSubmit: submitHandler
    });
    return (
        <>
            <div className="freesim-form-section forgot-password-section">
                <div className="form">
                    <h3>{t("login.forgotPwd")}</h3>
                    <p>{t("forgotPwd.description")}</p>
                    <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <div className="input-field">
                        <PR.InputText id="email" name="email" placeholder={t("placeholder.emailAddress")} className="w-12" value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} autoComplete="off" />
                        {formik.errors.email && formik.touched.email ? <div className='error-message'>{t(formik.errors.email)}</div> : ''}
                    </div>
                    <PR.Button label={t("forgotPwd.buttonText")} type='submit' disabled={!formik.isValid} className="login-btn signup-btn" />
                    <div className="text-center mt-5 px-6">
                        <p>{t("forgotPwd.backto")} <text onClick={() => { props.setAffiliatePage("signIn"); }}>{t("login.login")}</text></p>
                    </div>
                    </form>
                </div>   
            </div>
            <PR.Toast ref={toast} position="top-right" />
        </>
    );
};

export default EsimForgotPassword;