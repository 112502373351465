import React, { useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import * as PR from "../../../prime-modules/index";
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from "react-i18next";

import { authActions } from "../../../store/auth";
import { login } from '../../../services/api';
import GoogleLoginComponent from "../../login/GoogleLogin";
import { trimFormData } from "../../../utils/reuse";
import AppleLoginComponent from "../../login/AppleLogin";
import '../freeEsimSignUp/FreeEsimSignUp.scss';
import PaymentModal from "../PaymentModal";
import { checkFreeSims } from "../../../shared/methods/affiliateCheckout";
import { globalConfig } from "../../../constants";
import { ynertiaPaymentResponseActions } from "../../../store/ynertiaResponse";

const EsimLogin = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const toast = useRef();
    const [checkoutFormState, setCheckoutFormState] = useState(false);
    const affiliatePath = useSelector(state => state.auth.affiliatePath);
    const affiliateTrackingData = useSelector(state => state.affiliateTracking.affiliateTrackingInfo ? state.affiliateTracking.affiliateTrackingInfo : affiliatePath?.afid);
    const headers = useMemo(() => {
        return { afid: affiliateTrackingData };
    }, [affiliateTrackingData]);
    const [loading, setLoading] = useState(false)

    const initialValues = {
        email: "",
        password: "",
    };
    const validationSchema = () => {
        return Yup.object().shape({
            email: Yup.string().trim()
                .required(t("validation.email"))
                .email(t("validation.validEmail")),
            password: Yup.string().trim().required(t("validation.password"))
        });
    }

    const handleSubmit = (formData) => {
         setLoading(true)
         const getLoginResponse = (response) => {
             if (response.result === "SUCCESS") {
                setLoading(false)
                 dispatch(authActions.onLogin(response.data));
                 const localPhoneNumber = response.data.phone?.localPhoneNumber ? response.data.phone?.localPhoneNumber : '';
                 const freeSims = response.data?.freeesims ? response.data?.freeesims : 0;
                 dispatch(ynertiaPaymentResponseActions.setFreeSimsCount(freeSims));
                 const newProps = { ...props, setCheckoutFormState, setLoading };
                 checkFreeSims(affiliatePath, localPhoneNumber, freeSims, dispatch, newProps, toast, t, response.data, headers);
             } else {
                 setLoading(false)
                 const error = response.error;
                 const errorMsg = (error.errorMsg) ? error.errorMsg : error.summary;
                 toast.current.show({ life: globalConfig.toastDisplayTime, severity: error.severity, summary: t("toastSummary.error"), detail: t(`errorMsg.${errorMsg}`) })
             }
         }
         const trimmedFormData = trimFormData(formData);
         login(trimmedFormData, headers, dispatch, getLoginResponse);
    }
    
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit
    });

    return (
        <>
            <div className="freesim-form-section">
                <div className="text-right form-top-link">
                    <p> {t("login.dontHaveAnAccount")} <text onClick={() => props.setAffiliatePage("signUp")}>{t("login.signup")}</text></p>
                </div>
                <div className="form">
                    <h3>{t("login.login")}</h3>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="input-field">
                            <PR.InputText id="login-email" name="email" readOnly={loading} className="w-12" placeholder={t("placeholder.emailAddress")} value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} autoComplete="off" />
                            {formik.errors.email && formik.touched.email
                                ? <div className='error-message'>{formik.errors.email}</div>
                                : ''
                            }
                        </div>
                        <div className="input-field">
                            <PR.Password feedback={false} id="password" name="password" readOnly={loading} className="w-12" toggleMask autoComplete="off" placeholder={t("placeholder.password")} value={formik.values.password} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                            {formik.errors.password && formik.touched.password
                                ? <div className='error-message'>{formik.errors.password}</div>
                                : ''
                            }
                        </div>
                        <text onClick={() => props.setAffiliatePage("forgotPassword")} className="forgot-btn">{t("login.forgotPwd")}</text>
                        <PR.Button label={!loading ? affiliatePath?.isFree ? t("login.login") : "Continue to Payment" : "Loading..."} type='submit' disabled={!formik.isValid || loading} className="login-btn mt-4" />
                        <span className="or-span">{t("login.or")}</span>
                        <div className="flex align-items-center justify-content-center gap-3 mobile-flex">
                            <GoogleLoginComponent displayName={t("login.googleSignIn")} setAffiliatePage={props.setAffiliatePage} setCheckoutFormState={setCheckoutFormState} setLoading={props.setLoading}/>
                            <AppleLoginComponent displayName={t("login.appleSignIn")} setAffiliatePage={props.setAffiliatePage} setCheckoutFormState={setCheckoutFormState} setLoading={props.setLoading}/>
                        </div>
                    </form>
                    <div className="flex checkbox">
                        <label htmlFor="account">By clicking Sign In, I agree that I have read and accepted the <Link to="/terms-and-conditions" target="_blank">Terms of Service</Link> and <Link to="/privacy-policy" target="_blank">Privacy Policy</Link>.</label>
                    </div>
                </div>
            </div>
            <PaymentModal checkoutFormState={checkoutFormState} setCheckoutFormState={setCheckoutFormState} affiliatePath={affiliatePath} setAffiliatePage={props?.setAffiliatePage}/>

            <PR.Toast ref={toast} position="top-right" />
        </>
    );
}

export default EsimLogin;