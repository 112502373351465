import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { authActions } from '../store/auth';
import AffiliateEsim from '../components/AffiliateRedirect/AffiliateEsim';

const AffiliateEsimProvider = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const [affiliatePage, setAffiliatePage] = useState("");
   const affiliatePath = useSelector(state => state.auth.affiliatePath);

  const afid = new URLSearchParams(search)?.get("afid") ?? affiliatePath?.afid;
  const bundleId = new URLSearchParams(search)?.get("bundleId") ?? affiliatePath?.bundleId;
  const freesim = new URLSearchParams(search)?.get("isFree") ?? affiliatePath?.isFree;
  const isFree = (freesim === 'true' || freesim === true);

  useEffect(() => {
    const affiliateParams = {
      afid: afid,
      bundleId: bundleId,
      isFree: isFree
    };
    dispatch(authActions.storeAffiliatePath(affiliateParams));
    const params = new URLSearchParams(affiliateParams);
    navigate(`/redirect-affiliate?${params.toString()}`);
  }, [afid, bundleId, dispatch, isFree, navigate])

  return (
    <AffiliateEsim affiliatePage={affiliatePage} setAffiliatePage={setAffiliatePage} />
  )
}

export default AffiliateEsimProvider;