export const ScrollTo = (ref, offset) => {
    const element = ref.current;
    if (element) {
        const scrollOffset = offset;
        const elementPosition = element.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({
            top: elementPosition + scrollOffset,
            behavior: 'smooth'
        });
    }
  }