import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import * as PR from "../../prime-modules/index";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { formsLogo } from "../../assets/images";
import { getBundleDetails, googleLogin } from '../../services/api';
import { renderPrice, renderSpeed, setDataAmount } from "../../utils/reuse";
import './freeEsimSignUp/FreeEsimSignUp.scss';

import EsimSignUp from './freeEsimSignUp/FreeEsimSignUp';
import EsimLogin from './freeEsimLogin/EsimLogin';
import VerifyPhoneNumber from './VerifyPhoneNumber';
import FreeEsimDetails from './freeEsimDetails/FreeEsimDetails';
import EsimForgotPassword from './freeEsimForgotPassword/EsimForgotPassword';
import ExistingUser from './ExistingUser';
import { ynertiaPaymentResponseActions } from '../../store/ynertiaResponse';
import { useLocation } from 'react-router-dom';
import { stateActions } from '../../store/stateKey';
import { getAllCountries } from '../../shared/methods/GetAllCountries';
import { authActions } from '../../store/auth';
import { checkFreeSims, checkoutOrder } from '../../shared/methods/affiliateCheckout';
import { envConfig, globalConfig } from '../../constants';
import PaymentModal from './PaymentModal';

const AffiliateEsim = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const toast = useRef();
    const [affiliatePage, setAffiliatePage] = useState('signUp');
    const existingUserStatus = useSelector((state) => state.auth.affiliateExsitingUser);
    const affiliatePath = useSelector(state => state.auth.affiliatePath);
    const [checkoutFormState, setCheckoutFormState] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [loading, setLoading] = useState(false);
    const [isValidBundle, setIsvalidBundle] = useState(false);
    const countryCode = envConfig.REACT_APP_DEFAULT_PHONE_COUNTRY.toUpperCase();

    const { search } = useLocation();
    const getCode = new URLSearchParams(search).get("code");
    const state = new URLSearchParams(search).get("state");
    const stateKey = useSelector(state => state.stateKey.stateKey);

    const [bundleDetails, setBundleDetails] = useState({});
    const affiliateTrackingData = useSelector(state => state.affiliateTracking.affiliateTrackingInfo ? state.affiliateTracking.affiliateTrackingInfo : affiliatePath?.afid);
    const headers = useMemo(() => {
        return { afid: affiliateTrackingData };
    }, [affiliateTrackingData]);

    const getBundleData = useCallback((bundleId) => {
        setLoading(true);
        const getBundleList = (response) => {
            if (response.result === "SUCCESS") {
                setLoading(false);
                const details = response?.data ? response?.data : {};
                checkoutOrder(details, dispatch);
                setBundleDetails(details);
                setIsvalidBundle(false);
            } else {
                setLoading(false);
                setIsvalidBundle(true);
            }
        };
        getBundleDetails(bundleId, headers, dispatch, getBundleList)
    }, [dispatch, headers]);

    useEffect(() => {
        if (affiliatePath?.bundleId) {
            getBundleData(affiliatePath?.bundleId);
        }
    }, [affiliatePath?.bundleId, getBundleData]);

    const googleLoginHandler = useCallback(() => {
        if (getCode && state) {
            if (state === stateKey) {
                const loginDetails = (response) => {
                    dispatch(stateActions.removeStateKey());
                    if (response.result === "SUCCESS") {
                        const callingCode = response.data.phone?.callingCode ? response.data.phone?.callingCode : '';
                        getAllCountries(toast, t, dispatch, callingCode, countryCode);
                        dispatch(authActions.onLogin(response.data));

                        const localPhoneNumber = response.data.phone?.localPhoneNumber ? response.data.phone?.localPhoneNumber : '';
                        const freeSims = response.data?.freeesims ? response.data?.freeesims : 0;
                        dispatch(ynertiaPaymentResponseActions.setFreeSimsCount(freeSims));

                        const newProps = { setAffiliatePage, setCheckoutFormState, setLoading };
                        checkFreeSims(affiliatePath, localPhoneNumber, freeSims, dispatch, newProps, toast, t, response.data, headers);
                    } else if (response.result === "FAILED") {
                        const error = response.error;
                        const errorMsg = (error.errorMsg) ? error.errorMsg : error.summary;
                        toast.current.show({ life: globalConfig.toastDisplayTime, severity: error.severity, summary: t("toastSummary.error"), detail: t(`errorMsg.${errorMsg}`) })
                        setTimeout(() => {
                            setAffiliatePage('signUp');
                        }, [2000])
                    }
                }
                googleLogin(getCode, headers, dispatch, loginDetails)
            } else if (stateKey) {
                dispatch(stateActions.removeStateKey())
                toast.current.show({ life: globalConfig.toastDisplayTime, severity: 'error', summary: t("toastSummary.error"), detail: t('validation.invalidSignin') })
            }
        } else {
            getAllCountries(toast, t, dispatch, '', countryCode);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        googleLoginHandler();
    }, [googleLoginHandler]);

    return (
        <>
            <section className='freesim-section'>
                <div className="grid grid-nogutter header-text">
                    <div className="col-12 lg:col-4 md:col-12 hide-mobile">
                        <div className="login-bg">
                            {JSON.stringify(bundleDetails) !== '{}' && <div className="surface-border plan-box">
                                {/* <PR.Image alt="Logo" className="branding-logo" /> */}
                                <h3>{bundleDetails?.description}</h3>
                                <div className="mb-5">
                                    <h2><span className={affiliatePath?.isFree && existingUserStatus !== true ? "line-through" : ""}>{renderPrice(bundleDetails?.price)}</span> {affiliatePath?.isFree && existingUserStatus !== true ? 'Free' : ''}</h2>
                                </div>
                                <p className="pi pi-check"><span className="font-semibold">{setDataAmount(bundleDetails, t)}</span></p>
                                <p className="pi pi-check"><span>{renderSpeed(bundleDetails?.speed)}</span></p>
                                <p className="pi pi-check"><span>{t("home.stableConnection")}</span></p>
                                <p className="pi pi-check"><span>{t("home.easyConfiguration")}</span></p>
                            </div>
                            }
                            <div className="logo-section">
                                <p className="m-0">Powered by</p>
                                <PR.Image src={formsLogo} alt="Logo" className="logo" />
                            </div>
                        </div>
                    </div>
                    { !isValidBundle ? <div className="col-12 lg:col-8 md:col-12">
                        {affiliatePage === "signUp" ? <EsimSignUp setAffiliatePage={setAffiliatePage} setCheckoutFormState={setCheckoutFormState} setLoading={setLoading} />
                            : affiliatePage === "signIn" ? <EsimLogin setAffiliatePage={setAffiliatePage} setLoading={setLoading} />
                                : affiliatePage === "verifyPhone" ? <VerifyPhoneNumber setAffiliatePage={setAffiliatePage} setLoading={setLoading}/>
                                    : affiliatePage === "esimDetails" ? <FreeEsimDetails setAffiliatePage={setAffiliatePage} />
                                        : affiliatePage === "forgotPassword" ? <EsimForgotPassword setAffiliatePage={setAffiliatePage} />
                                            : affiliatePage === "existingUser" ? <ExistingUser setCheckoutFormState={setCheckoutFormState} setAffiliatePage={setAffiliatePage} />
                                                : <></>}
                    </div> :
                    <div className="col-12 lg:col-8 md:col-12">
                        <div className="verify-phonenumber-section">
                            <div className="form-section">
                                <div className="form">
                                <p>The provided bundle ID is not valid. Use the valid details</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </section>

            <PR.Toast ref={toast} position="top-right" />
            <PaymentModal checkoutFormState={checkoutFormState} setCheckoutFormState={setCheckoutFormState} affiliatePath={affiliatePath} setAffiliatePage={setAffiliatePage} />
        </>
    )
}

export default AffiliateEsim;