import React from 'react';
import HeaderMenu from './layout/HeaderMenu';

const SubHeader = (props) => {
    return (
        <section className="inner-header-section aboutus-header-section">
            <div className="header">
                <header>
                    <HeaderMenu />
                </header>
                <div className="fixed-height"></div>
            </div>

            <div className="custom-container">
                <div className="grid grid-nogutter header-text">
                    <div className="col-12">
                        <h1>{props.header}</h1>
                        <p>{props.subText}</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SubHeader;