import React, { useState, useMemo, useRef, useEffect } from 'react'
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as PR from "../../prime-modules/index";
import { updateUserProfile } from '../../services/api';
import { trimFormData } from "../../utils/reuse";
import { useTranslation } from "react-i18next";
import CustomPhoneNumber from '../CustomPhoneNumber';
import { globalConfig } from '../../constants';
import "./freeEsimDetails/FreeEsimDetails.scss";
import { parsePhoneNumber } from 'react-phone-number-input';
import { checkFreeSims } from '../../shared/methods/affiliateCheckout';
import PaymentModal from './PaymentModal';

function VerifyPhoneNumber(props) { 
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useRef('');
  const userData = useSelector(state => state.auth.sessionData);
  const freeSimsCount = useSelector(state => state.ynertia.freeSimsCount);
  const [phoneValue, setPhoneValue] = useState('');
  const [phoneError, setphoneError] = useState('');
  const [checkoutFormState, setCheckoutFormState] = useState(false);
  const [loading, setLoading] = useState(false);
  const affiliatePath = useSelector(state => state.auth.affiliatePath);
  const headers = useMemo(() => {
    return { 
      afid: affiliatePath?.afid,
      sessionid: userData?.sessionId
    };
  }, [affiliatePath?.afid, userData?.sessionId]);

  const formInitialValues = {
    phone: "",
  };

  const handleSubmit = (formData) => {
    const trimmedFormData = trimFormData(formData);
    const phone = {
      localPhoneNumber: phoneValue ? phoneValue : '',
      callingCode: trimmedFormData?.callingCode ? trimmedFormData?.callingCode : '',
    }

    const requestObj = {
      userId: userData?.userId ?? 0,
      phone: phone,
      email: userData?.email
    };
    updateUserProfile(requestObj, headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        formik.resetForm();
        const newProps = { ...props, setCheckoutFormState, setLoading };
        checkFreeSims(affiliatePath, phoneValue, freeSimsCount, dispatch, newProps, toast, t, userData, headers);
        toast.current.show({
          life: globalConfig.toastDisplayTime,
          severity: "success",
          summary: t("toastSummary.success"),
          detail: t("toast.userSuccessUpdate"),
        });
      } else {
        const error = response.error;
        const errorMsg = error.errorMsg ? error.errorMsg : error.summary;
        toast.current.show({
          life: globalConfig.toastDisplayTime,
          severity: error.severity,
          summary: t("toastSummary.error"),
          detail: t(`errorMsg.${errorMsg}`),
        });
      }
    });
  };

  const formik = useFormik({
    initialValues: formInitialValues,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    let isValidPhone = parsePhoneNumber(formik.values.phone);
    if (!phoneValue) {
      setphoneError(t("validation.phone"))
    } else if (isValidPhone?.nationalNumber.length <= 5 || isValidPhone?.nationalNumber === undefined) {
      setphoneError(t("validation.phoneMinMaxError"))
    } else {
      setphoneError("");
    }
  }, [formik.values.phone, phoneValue, t])

  return (
    <div className="verify-phonenumber-section">
      <div className="form-section">
        <div className="form">
          <h3>Hey {`${userData?.firstName ? userData?.firstName : ""} ${userData?.lastName ? userData?.lastName : ""}`},</h3>
          <p>Please provide and verify your phone number to continue to get your free eSIM.</p>
          <form onSubmit={formik.handleSubmit}>
            <div className="input-field">
              <div className="flex flex-column">
                <CustomPhoneNumber phoneError={phoneError} formik={formik} t={t} setPhoneValue={setPhoneValue} />
              </div>
            </div>
            <PR.Toast ref={toast} position='top-right' />
            <PR.Button label="Continue" type='submit' className="login-btn mt-5" disabled={phoneError || loading} />
          </form>
        </div>
      </div>
      <PaymentModal checkoutFormState={checkoutFormState} setCheckoutFormState={setCheckoutFormState} affiliatePath={affiliatePath} setAffiliatePage={props?.setAffiliatePage}/>
    </div>
  )
}

export default VerifyPhoneNumber;