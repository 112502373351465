import React from "react";
import "../about-us/AboutUs.scss";
import * as PR from "../../prime-modules/index";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from "react-router-dom";
import { globalConfig } from "../../constants";
import SubHeader from "../../components/SubHeader";
import SupportMailsSection from "../../components/SupportMailsSection";
import ContactForm from "../../components/ContactForm";
import { useToast } from "../../context/ToastContext";
import getAssets from "../../assets/data/brandingAssets.json"

const AboutUs = () => {
  const { labels, aboutus, general, isBase64Image } = useToast();


  return (
     <HelmetProvider> 
      <Helmet>
          <meta charSet="utf-8" />
          <title>{`${general.GEN0001} | ${aboutus?.AB0001}`} </title>
      </Helmet>

      {/* Header Section */}
      <SubHeader
        header={aboutus.AB0001}
        subText={
          <>
            {aboutus.AB0002}
          </>
        }
      />      

      <section className="about-blocks-section">
        <div className="custom-container">
          <div className="grid align-items-center custom-margin">
              <div className="col-12 lg:col-6 md:col-6">
                <PR.Image src={isBase64Image(aboutus.AB0003) ? `data:image/png;base64,${aboutus.AB0003}`: getAssets.about_us.unlock_world} className="about-img" alt="Unlock a World of Seamless Connectivity"/>
              </div>
              <div className="col-12 lg:col-6 md:col-6 right">
                <h2>{aboutus.AB0004}</h2>
                <p>{aboutus.AB0005}</p>
              </div>
          </div>
          <div className="grid align-items-center custom-margin column-reverse">
              <div className="col-12 lg:col-6 md:col-6 right">
                <h2>{aboutus.AB0006}</h2>
                <p>{aboutus.AB0007}</p>
              </div>
              <div className="col-12 lg:col-6 md:col-6">
                <PR.Image src={isBase64Image(aboutus.AB0008) ? `data:image/png;base64,${aboutus.AB0008}`: getAssets.about_us.instant_activation} className="about-img" alt="Instant Activation"/>
              </div>
          </div>
          <div className="grid align-items-center custom-margin">
              <div className="col-12 lg:col-6 md:col-6">
                <PR.Image src={isBase64Image(aboutus.AB0009) ? `data:image/png;base64,${aboutus.AB0009}`: getAssets.about_us.global_coverage} className="about-img" alt="Global Coverage"/>
              </div>
              <div className="col-12 lg:col-6 md:col-6 right">
                <h2>{aboutus.AB0010}</h2>
                <p>{aboutus.AB0011}</p>
              </div>
          </div>
          <div className="grid align-items-center custom-margin column-reverse">
              <div className="col-12 lg:col-6 md:col-6 right">
                <h2>{aboutus.AB0012}</h2>
                <p>{aboutus.AB0013}</p>
              </div>
              <div className="col-12 lg:col-6 md:col-6">
                <PR.Image src={isBase64Image(aboutus.AB0014) ? `data:image/png;base64,${aboutus.AB0014}`: getAssets.about_us.attractive_rates} className="about-img" alt="Attractive Rates"/>
              </div>
          </div>
        </div>
      </section>

       {/* Form Section */}
       <section className="about-partners-section">
        <div className="grid align-items-center">
          <div className="col-12 lg:col-5 md:col-6">
            <div className="partners-text-box flex justify-content-center">
                <div>
                  <h2>{labels.LBL0297}</h2>
                  <p>{labels.LBL0298} <Link to={`mailto:${globalConfig.salesEmail}`}>{globalConfig.salesEmail}</Link></p>
                   <ContactForm formType={"aboutus"}/>
                </div>
            </div>
          </div>
          <div className="col-12 lg:col-7 md:col-6 text-center">
            <div className="option-box">
                <PR.Image className="benefits-img" src={isBase64Image(aboutus.AB0018) ? `data:image/png;base64,${aboutus.AB0018}`: getAssets.about_us.aboutus_featureImage} alt="Benefits"/>
            </div>
          </div>
        </div>
      </section>

      <section className="inner-header-section aboutus-header-section support-section">
        <SupportMailsSection/>
      </section>
    </HelmetProvider>
  );
};

export default AboutUs;