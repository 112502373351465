import React, { useRef, useMemo, useState, useEffect, useCallback } from "react";
import EdiText from 'react-editext';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux'
import * as PR from "../../../prime-modules/index";

import { globalConfig } from '../../../constants';
import { authActions } from "../../../store/auth";
import {  updateTag } from '../../../services/api'
import "./FreeEsimDetails.scss";
import { esimIcon2 } from "../../../assets/images";
import { ynertiaPaymentResponseActions } from "../../../store/ynertiaResponse";
import { cartFilterActions } from "../../../store/cart";

const FreeEsimDetails = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ynertiaPaymentResponse = useSelector(state => state.ynertia.ynertiaPaymentResponse);
  const userData = useSelector((state) => state.auth.sessionData);
  const [value, setValue] = useState(ynertiaPaymentResponse?.tag || "")
  const toast = useRef(null);
  const affiliatePath = useSelector(state => state.auth.affiliatePath);
  const affiliateExsitingUser = useSelector(state => state.auth.affiliateExsitingUser);
  const headers = useMemo(() => {
    return { afid: affiliatePath?.afid,
      sessionid: userData.sessionId
    };
  }, [affiliatePath?.afid, userData.sessionId]);

  const validateTagValue = (text) => {
    if (text.length === 0) {
      return true;
    } else if (text.length > 0) {
      return text.length >= 3 && text.length <= 10;
    }
  };

  const handleSave = (e) => {
    if (validateTagValue(e) && e !== value) {
      const iccid = ynertiaPaymentResponse?.iccid;
      const request = {
        userId: userData.userId ?? 0,
        iccid: iccid,
        tag: e,
      };
      const tagResponse = (response) => {
        if (response.result === "SUCCESS") {
          setValue(e);
          toast.current.show({
            life: globalConfig.toastDisplayTime,
            severity: "success",
            summary: t("toastSummary.success"),
            detail: t("myAccount.tagSuccess.sucessMsg"),
          });
        } else if (response.result === "FAILED" && response.error) {
          const error = response.error;
          const errorMsg = error.errorMsg ? error.errorMsg : error.summary;
          toast.current.show({
            life: globalConfig.toastDisplayTime,
            severity: error.severity,
            summary: t("toastSummary.error"),
            detail: t(`errorMsg.${errorMsg}`),
          });
        }
      };
      updateTag(request, headers, dispatch, tagResponse);
    }
  };

  const handleNavigateContact = () => {
    dispatch(authActions.deleteStoreIdPath())
  }

  const handleNavigateAffiliateSite = useCallback(() => {
    if(ynertiaPaymentResponse?.finalRedirectURL) {
      window.location.href = ynertiaPaymentResponse?.finalRedirectURL;
      dispatch(authActions.onLogout());
      dispatch(cartFilterActions.deleteCatalogCart());
      dispatch(authActions.deleteStoreIdPath());
      dispatch(ynertiaPaymentResponseActions.deleteYnertiaPaymentResponse());
    }
  }, [dispatch, ynertiaPaymentResponse?.finalRedirectURL]);

  useEffect(() => {
    // Set a timeout to navigate to an external link after 3 seconds
    setTimeout(() => {
      handleNavigateAffiliateSite();
    }, 3000);

  }, [handleNavigateAffiliateSite]);

  return (
    <div className="free-esim-section">
      <div className="form-section">
        <div className="text-right form-top-link">
          <p>Facing any issue? <Link onClick={handleNavigateContact} target="_blank" to="/contact">Contact Support</Link></p>
        </div>
        <div className="form">
       <>
            <h3>{(affiliateExsitingUser || !affiliatePath?.isFree) ? "Thank you for your purchase!" : "Here's your free eSIM!"}</h3>
            <div className="esim-box">
              <ul className="flex justify-content-between user-edit-ul">
                <li>
                  <p className="id_p">{ynertiaPaymentResponse?.iccid}</p>
                </li>
                <li>
                 {ynertiaPaymentResponse?.profileStatus && <span className="status-box Released">{ynertiaPaymentResponse?.profileStatus}</span>}
                </li>
              </ul>
              {affiliatePath?.isFree ? null : 
                userData.sessionId && <ul className="flex align-items-center user-edit-ul">
                  <li>
                    <span>
                      <EdiText
                        name="tag"
                        value={ynertiaPaymentResponse?.tag}
                        type="text"
                        placeholder={t("myAccount.screen.grid.tag")}
                        onSave={(e) => handleSave(e)}
                        validation={validateTagValue}
                        validationMessage={t("validation.tagMinMaxError")}
                        onCancel={(e) => e}
                        className="edit-tag"
                      />
                    </span>
                  </li>
              </ul>}
              <div className="esim-not-installed">
                <PR.Image src={esimIcon2} alt="fee esim" className="esim-icon" />
                <p className="font-weight-light">Redirecting you back to the affiliate site.</p>
              </div>
            </div>
            <PR.Toast ref={toast} position='top-right' />
            <div className="text-center">
              <PR.Button label="Continue" type='button' className="continue-btn" onClick={handleNavigateAffiliateSite}/>
              <p className="custom-width">Click above button if you are not automatically redirected to 'affiliate site' </p>
            </div>
          </>
        </div>
      </div>
    </div>
  )
}

export default FreeEsimDetails