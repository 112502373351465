import React, { useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { envConfig, globalConfig } from "../../constants";
import { paypalPayment } from "../../services/api";
import { v4 as uuidv4 } from 'uuid';
import { cartFilterActions } from "../../store/cart";
import { getPhoneObj } from "../../shared/methods/GetAllCountries";
import { useToast } from "../../context/ToastContext";

const PaypalPayment = ( props ) => {
    const { showToast } = useToast()
    const dispatch = useDispatch();
    const isAuth = useSelector(state => state.auth.isLoggedIn);
    const currency = globalConfig.paymentDefaultCurrency.toUpperCase();
    const cartData = useSelector((state) => state.cart.catalogCart);
    const iccid = useSelector(state => state.esim.esimIccid);
    const userData = useSelector(state => state.auth.sessionData);
    const randomId = useSelector((state) => state.cart.randomId);
    const affiliatePath = useSelector(state => state.auth.affiliatePath);
    const affiliateTrackingData = useSelector(state => state.affiliateTracking.affiliateTrackingInfo ? state.affiliateTracking.affiliateTrackingInfo : affiliatePath?.afid);
    const headers = useMemo(() => {
        return { sessionid: userData.sessionId, afid: affiliateTrackingData };
    }, [userData.sessionId, affiliateTrackingData]);
    const totalPayAmount = ('promoCode' in cartData) ? cartData.discountedPrice : cartData.price;
  
    const createOrder = (data, actions) => {
        const cart_data = [{
            name: cartData.name,
            description: cartData.description,
            unit_amount: {
                currency_code: currency,
                value: totalPayAmount,
            },
            quantity: cartData.quantity,
        }];

        const purchaseUnits = {
            purchase_units: [
                {
                    amount: {
                        currency_code: currency,
                        value: totalPayAmount,
                        breakdown: {
                            item_total: {
                                currency_code: currency,
                                value: totalPayAmount
                            },
                        }
                    },
                    items: cart_data
                },
            ],
            application_context: {
                shipping_preference: 'NO_SHIPPING'
            }
        }

        return actions.order.create(purchaseUnits)
            .then((orderID) => {
                return orderID
            })
    }

    const onApprove = (data, actions) => {
        return actions.order.capture().then(function (e) {
            const payMethodID = e.id
            if (payMethodID) {
                props.paymentProps("", true);
                createPaypalPayment(e);
            } else {
                props.paymentProps("", false)
                props.setPaymentType("")
                showToast('error', "PMTMSG-15")
            }
        })
    }

    const onError = (data, actions) => {
        props.paymentProps("", false)
    }

    const initialOptions = {
        "client-id": envConfig.REACT_APP_PAYPAL_CLIENT_ID
    };

    const createPaypalPayment = (paydetails) => {
        props.paymentProps("", true)

        const getPaymentData = (response) => {
            if (response.result === "SUCCESS") {
                paymentResponse(response.data);
            } else {
                props.paymentProps("", false)
                const error = response.error;
                props.setPaymentType("")
                showToast(error.severity, error.errorMsg);
            }
        }

        const paymentMethodID = paydetails.id;
        if (paymentMethodID && paymentMethodID !== '') {
            const getCartObj = { ...cartData };
            delete getCartObj.discountedPercentage;
            delete getCartObj.discountedPrice;
            delete getCartObj.dataAmountForDisplay;
            delete getCartObj.promoCodePrice;

            const dataPlans = [getCartObj];

            const customerDetails = {
                firstName: paydetails.payer.name.given_name,
                lastName: paydetails.payer.name?.surname ?? "",
                email: paydetails.payer.email_address,
                phone: getPhoneObj('', isAuth, userData, '')
            }
            const deviceInfo = { device: globalConfig.deviceInfo };
            const paymentData = {
                contactInfo: customerDetails,
                orderSummary: {
                    dataPlans: dataPlans,
                    totalOrderAmount: cartData.price
                },
                paymentGateway: "Paypal",
                paymentMethodId: paymentMethodID,
                iccid: iccid,
                isEsimCompatible: globalConfig.isEsimCompatible,
                deviceInfo: deviceInfo,
                randomId:randomId ? randomId : uuidv4(),
            }
            paypalPayment(paymentData, headers, dispatch, getPaymentData)
        }
    }

    const paymentResponse = (payRes) => {
        if (payRes.status === "succeeded") {
            props.paymentProps(payRes, false);
            dispatch(cartFilterActions.removeRandomIdInPayment());
        } else {
            props.paymentProps("", false)
            props.setPaymentType("")
            showToast("error", "PMTMSG-16")
        }
    }

    return (
        <>
            <div className="col-12 md:col-12">
                <div className="input-wrapper paypal-section-button payment-section">
                    <PayPalScriptProvider options={initialOptions} >
                        <PayPalButtons fundingSource="paypal" style={{ layout: 'vertical' }} createOrder={createOrder} onApprove={onApprove} onError={onError} onCancel={() => props.paymentProps("", false)} />
                    </PayPalScriptProvider>
                </div>
            </div>
        </>
    );
};

export default PaypalPayment;