import React, { useEffect } from 'react';
// **** Main CSS **** //
import './assets/scss/main.scss';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/home/Home';
import Contact from './pages/contact/Contact';
import AboutUs from './pages/about-us/AboutUs';
import Partners from './pages/partners/Partners';
import MyAccount from './pages/my-account/MyAccount';
import RedirectVerifyEmail from './components/RedirectVerifyEmail';
import TermsAndConditions from './pages/terms-and-conditions/TermsAndConditions';
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy';
import CookiePolicy from './pages/cookie-policy/CookiePolicy';
import PageNotFound from './pages/page-not-found/PageNotFound';
import Faq from './pages/faq/Faq';
import Maintenance from './pages/maintenance/Maintenance';
import CompatibleDevices from './pages/compatible-devices/CompatibleDevices';
import { useDispatch, useSelector } from 'react-redux';
import ScrollToTop from './components/layout/ScrollToTop';
import Footer from './components/layout/Footer';
import BundleDetails from './pages/bundle-details/BundleDetails';
import Paypal from './pages/web-paypal/Paypal';
import Affiliate from './pages/affiliate/Affiliate';
import AffiliateEsimProvider from './provider/AffiliateEsimProvider';
import PaymentSummary from './components/payment/PaymentSummary';
// import CMS from './CMS';
import { useTranslation } from 'react-i18next';
import en from "./assets/data/assets.json"
import es from "./assets/data/assets-es.json"
import pt from "./assets/data/assets-pt.json"
import { messageCatalogActions } from './store/messageCatalog';
import getAssets from "./assets/data/brandingAssets.json"
import { useToast } from './context/ToastContext';

function App() {
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const siteMaintenance = useSelector(state => state.sitemaintenance.sitemaintenanceInfo);
  const isUnderMaintenance = (siteMaintenance === 'true') ? true : false;
  const { i18n } = useTranslation();
  const { themeColors } = useToast();
  const dispatch = useDispatch();
  useEffect(() => {
   const data = i18n.language === 'es' ? es :  i18n.language === 'pt' ? pt :en;
   dispatch(messageCatalogActions.storeAll(data))
  }, [i18n.language, dispatch]);
  
  const trimColorCode = (colors) => colors ? colors.replace(/"/g, '').trim(): '';
  document.documentElement.style.setProperty('--font-base', getAssets.fonts['font-base']);
  document.documentElement.style.setProperty('--font-secondary', getAssets.fonts['font-secondary']);
  document.documentElement.style.setProperty('--general_font_size', getAssets.fonts['general_font_size']);
  document.documentElement.style.setProperty('--white', trimColorCode('#ffffff'));
  document.documentElement.style.setProperty('--black', trimColorCode('#283249'));
  document.documentElement.style.setProperty('--error-msg', trimColorCode('#ff1900'));
  document.documentElement.style.setProperty('--primary', trimColorCode(themeColors.PRIMARY));
  document.documentElement.style.setProperty('--secondary', trimColorCode(themeColors.SECONDARY));
  document.documentElement.style.setProperty('--teritary', trimColorCode(themeColors.TERITARY));
  document.documentElement.style.setProperty('--quaternary', trimColorCode(themeColors.QUATERNARY));
  document.documentElement.style.setProperty('--quinary', trimColorCode(themeColors.QUINARY));
  document.documentElement.style.setProperty('--mainmenu', trimColorCode(themeColors.MAINMENU));
  document.documentElement.style.setProperty('--mainmenuover', trimColorCode(themeColors.MAINMENUOVER));
  document.documentElement.style.setProperty('--btnprimary', trimColorCode(themeColors.BTNPRIMARY));
  document.documentElement.style.setProperty('--btnprimaryover', trimColorCode(themeColors.BTNPRIMARYOVER));
  document.documentElement.style.setProperty('--btnsecondary', trimColorCode(themeColors.BTNSECONDARY));
  document.documentElement.style.setProperty('--btnsecondaryover', trimColorCode(themeColors.BTNSECONDARYOVER));
  document.documentElement.style.setProperty('--input-field', trimColorCode(themeColors.INPUTFIELD));
  document.documentElement.style.setProperty('--input-border', trimColorCode(themeColors.INPUTFIELDBORDER));
  document.documentElement.style.setProperty('--dropdown-bg', trimColorCode(themeColors.DROPDOWNBG));
  document.documentElement.style.setProperty('--dropdown-selected', trimColorCode(themeColors.DROPDOWNSELECTED));
  return (
    <>
      <Router>
       {/* <CMS> */}
        <ScrollToTop />
        <Routes>
          <>
            {isUnderMaintenance
              ? <Route index element={<Maintenance />} />
              : <>
                <Route index exact path='/' element={< Home />} />
                <Route path='/about-us' element={< AboutUs />} />
                <Route path='/partners' element={< Partners />} />
                <Route path='/contact' element={< Contact />} />
                <Route path='/my-account' element={isLoggedIn ? < MyAccount /> : <Navigate to="/" />} />
                <Route path='/verify-email' element={<RedirectVerifyEmail />} />
                <Route path='/update-email' element={<RedirectVerifyEmail />} />
                <Route path='/reset-password' element={<RedirectVerifyEmail />} />
                <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
                <Route path='/cookie-policy' element={<CookiePolicy />} />
                <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                <Route path='/faq' element={<Faq />} />
                <Route path='/bundles/:id' element={<BundleDetails />} />
                <Route path='/paypal' element={<Paypal />} />
                <Route path='/compatible-devices' element={<CompatibleDevices />} />
                <Route path='/affiliate' element={<Affiliate />} />
                <Route path='/redirect-affiliate' element={<AffiliateEsimProvider />} />
                <Route path='/paymentresponse' element={<PaymentSummary />} />
                <Route path='*' element={<PageNotFound />} />
              </>
            }
          </>
        </Routes>
        {window.location.pathname !== '/paypal' && <Footer />}
        {/* </CMS> */}
      </Router>
    </>
  );
}

export default App;