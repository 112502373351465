import React from "react";
import "../partners/Partners.scss";
import * as PR from "../../prime-modules/index";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from "react-router-dom";
import SubHeader from "../../components/SubHeader";
import SupportMailsSection from "../../components/SupportMailsSection";
import ContactForm from "../../components/ContactForm";
import { useToast } from "../../context/ToastContext";
import getAssets from "../../assets/data/brandingAssets.json"

const Partners = () => {
  const { partner, isBase64Image, general} = useToast()
  return (
     <HelmetProvider> 
      <Helmet>
          <meta charSet="utf-8" />
          <title>{general.GEN0001}</title>
      </Helmet>

      {/* Header Section */}
      <SubHeader header={partner?.PART0001} subText={partner?.PART0002} />
      <section className="about-blocks-section">
        <div className="custom-container">
          <div className="grid align-items-center custom-margin">
              <div className="col-12 lg:col-6 md:col-6">
                <PR.Image src={isBase64Image(partner.PART0003) ? `data:image/png;base64,${partner.PART0003}`: getAssets.partners.partner1} className="about-img" alt="Enhanced Customer Experience"/>
              </div>
              <div className="col-12 lg:col-6 md:col-6 right">
                <h2>{partner?.PART0004} </h2>
                <p>{partner?.PART0005}</p>
              </div>
          </div>
          <div className="grid align-items-center custom-margin column-reverse">
              <div className="col-12 lg:col-6 md:col-6 right">
                <h2>{partner?.PART0006}</h2>
                <p>{partner?.PART0007}</p>
              </div>
              <div className="col-12 lg:col-6 md:col-6">
                <PR.Image src={isBase64Image(partner.PART0008) ? `data:image/png;base64,${partner.PART0008}`: getAssets.partners.partner2} className="about-img" alt="Streamlined Integration"/>
              </div>
          </div>
          <div className="grid align-items-center custom-margin">
              <div className="col-12 lg:col-6 md:col-6">
                <PR.Image src={isBase64Image(partner.PART0009) ? `data:image/png;base64,${partner.PART0009}`: getAssets.partners.partner3} className="about-img" alt="Win-Win Partnership"/>
              </div>
              <div className="col-12 lg:col-6 md:col-6 right">
                <h2>{partner?.PART0010}</h2>
                <p>{partner?.PART0011}</p>
              </div>
          </div>
          <div className="grid align-items-center">
              <div className="col-12 right text-center">
                <h2>{partner?.PART0012}</h2>
                <p>{partner?.PART0013}</p>
              </div>
          </div>
        </div>
      </section>

       {/* Partners Section */}
       <section className="about-partners-section">
        <div className="grid align-items-center">
          <div className="col-12 lg:col-5 md:col-6">
            <div className="partners-text-box flex justify-content-center">
                <div>
                  <h2>{partner?.PART0014}</h2>
                  <p>{partner?.PART0015} <Link to={`mailto:${partner.PART0016}`}>{partner.PART0016}</Link></p>
                  <ContactForm formType={"partners"} />
                </div>
            </div>
          </div>
          <div className="col-12 lg:col-7 md:col-6 text-center">
            <div className="option-box">
                <PR.Image className="benefits-img" src={isBase64Image(partner.PART0016) ? `data:image/png;base64,${partner.PART0016}`: getAssets.partners.partner4} alt="Benefits"/>
            </div>
          </div>
        </div>
      </section>

      <section className="inner-header-section aboutus-header-section support-section">
        <SupportMailsSection/>
      </section>
    </HelmetProvider>
  );
};

export default Partners;