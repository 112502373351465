import React, { useEffect } from "react";
import * as PR from '../../prime-modules'
import { useLocation} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { stateActions } from "../../store/stateKey";
import { googlePay } from "../../assets/images";
import { envConfig } from '../../constants'

const GoogleLoginComponent = (props) => {
    const dispatch = useDispatch();
    const { search } = useLocation();
    const getCode = new URLSearchParams(search).get("code");
    const state = new URLSearchParams(search).get("state");
    const stateKey = useSelector(state => state.stateKey.stateKey);
    const affiliatePath = useSelector(state => state.auth.affiliatePath);
  
  const getRandomKey = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }
    useEffect(() => {
        if (!getCode && !state) {
            dispatch(stateActions.storeStateKey(getRandomKey(10)))
        }
    }, [getCode, state, dispatch])


    const handleGoogleLogin = () => {
        const redirectUrl = affiliatePath?.afid ? process.env.REACT_APP_AFFILIATE_GOOGLE_CALLBACK_URL : envConfig.REACT_APP_GOOGLE_CALLBACK_URL;
        const clientId = envConfig.REACT_APP_GOOGLE_AUTH_CLIENT_KEY;
        const state = stateKey;
        const scopes = ['profile', 'email', 'https://www.googleapis.com/auth/user.phonenumbers.read'];
        const scope = scopes.join(' ');
        const authUrl = `https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUrl)}&scope=${encodeURIComponent(scope)}&state=${encodeURIComponent(state)}`;
        window.location.href = authUrl;
    };

    return (
        <>
            <div onClick={handleGoogleLogin} target="_blank" className="social-button">
                <PR.Image src={googlePay}/>
                <span className="googleBtnText">{props?.displayName}</span>
            </div>
        </>
    );
}
 
export default GoogleLoginComponent;